import {ElementModel} from "./ElementModel";
import {BlockType} from "../../../app/models/elements/Enums";

export class BlockModel extends ElementModel {
  text: string = '';
  type: BlockType;
  condition: string;
  repeat: string;
  position: number;
  elements = [];

  constructor(position: number, text: string, type: BlockType, condition: string, repeat: string) {
    super(position);
    this.text = text;
    this.type = type;
    this.condition = condition;
    this.repeat = repeat;
  }
}
