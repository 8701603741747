import {Component, Input} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Question} from "../Question";
import {QuestionYesNoModel} from "../../../models/elements/QuestionYesNoModel";

@Component({
  selector: 'app-yes-no',
  templateUrl: './yes-no.component.html',
  styleUrls: ['./yes-no.component.scss'],
})

export class YesNo extends Question {
  @Input() public loopIndex: number;
  model: QuestionYesNoModel;
  yesTranslated: string;
  noTranslated: string;
  value: boolean = null;

  constructor(
    private translateService: TranslateService
  ) {
    super();
    this.yesTranslated = this.translateService.instant('LESSON.YES');
    this.noTranslated = this.translateService.instant('LESSON.NO');
  }

  ngAfterViewInit() {
    //emit null (so that there is an entry for each repetition) or saved answer
    this.emitAnswer(this.currentValue);
  }

  ngOnDestroy(){
    console.log("destroy pos " + this.model.position + " loop" +  this.loopIndex)
    this.model.lesson.removeAnswerRepetition(this.model.position, this.loopIndex);
  }

  public emitAnswer(value: any) {
    if (value === undefined || this.model.lesson.loading) {
      return
    }
    // insert technical answer in global array
    this.model.lesson.insertAnswer(this.model.position, this.loopIndex, value);
    this.value = this.currentValue;
  }

  get currentValue(): boolean {
    return this.model.lesson.getAnswer(this.model.position, this.loopIndex)
  }
}
