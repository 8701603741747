<ion-split-pane contentId="appContent" when="(min-width: 992px)">
  <ion-menu side="start" menuId="appMenu" contentId="appContent" type="overlay" style="max-width: 300px">
    <ion-header>
      <ion-toolbar>
        <ion-title>
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="navigation-container">
        <ion-menu-button *ngIf="smallScreen" menu="appMenu" autoHide="false" slot="start" id="appmenuButton" (click)="closeMenu"></ion-menu-button>
        <div class="logo" slot="end">
          <img src="../../../assets/imgs/logo_transparent_bigger.png">
        </div>
        <div class="navigation-box">
          <ion-list lines="none">
            <ion-menu-toggle autoHide="false">
              <ng-container *ngFor="let pages of navigate">
                <ion-item *ngIf="showPage(pages.url)" [routerLink]="null" [class.activeLink]="previousUrl === pages.url" [attr.id]="pages.id"
                    (click)="routeToUrl(pages.url, $event)"> <!-- [disabled]="pages.url === '/open-interventions'" -->
                  <ion-icon [name]="pages.icon" slot="start"></ion-icon>
                  {{pages.title | translate}}
                  <ion-badge *ngIf="pages.url === '/invitations' && parserService.invitations.length > 0" slot="end"
                      class="pending" [class.pendingActive]="previousUrl === pages.url">
                    {{parserService.invitations.length}}
                  </ion-badge>
                  <ion-badge *ngIf="pages.url === '/threads/chat-overview' && (parserService.newMessages > 0 || parserService.newFeedback > 0)" slot="end"
                      class="pending" [class.pendingActive]="previousUrl === pages.url">
                    {{parserService.newMessages + parserService.newFeedback}}
                  </ion-badge>
                </ion-item>
              </ng-container>
            </ion-menu-toggle>
          </ion-list>
        </div>

        <div class="navigation-box navigation-spacer">
        </div>

        <div class="navigation-box">
          <ion-item class="technicalItem">
            <ion-icon name="help-circle-outline"></ion-icon>
            <a id="supportButton" (click)="openEmailSupport()" class="sidebarlink">
              {{"SUPPORT.TECHNICAL_SUPPORT" | translate}}
            </a>
          </ion-item>
        </div>

        <ion-footer class="imprintfooter">
          <ion-menu-toggle autoHide="false">
            <a class="sidebarlink" id="navImprint" (click)="routeToUrl('imprint', $event)">
              {{"PAGES.IMPRINT" | translate}}
            </a>
            |
            <a class="sidebarlink" id="navPrivacy" (click)="routeToUrl('privacy', $event)">
              {{"PAGES.PRIVACY" | translate}}
            </a>
            |
            <a class="sidebarlink" id="navSecurity" (click)="routeToUrl('security', $event)">
              {{"PAGES.SECURITY_DECLARATION" | translate}}
            </a>
          </ion-menu-toggle>
        </ion-footer>
      </div>
    </ion-content>
  </ion-menu>
  <ion-router-outlet id="appContent"></ion-router-outlet>
</ion-split-pane>
