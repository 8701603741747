import { Injectable } from '@angular/core';
import * as bcrypt from 'bcryptjs';
import { Storage } from '@ionic/storage';
import { HelperService } from "../../providers/helper/helper.service";
import { Answer } from "../../models/Answer";
// import { MediaType, QuestionTypes } from '../../../app/models/elements/Enums';
// import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
// import { Platform } from '@ionic/angular';
// import { SecureStorage, SecureStorageObject } from '@ionic-native/secure-storage/ngx';
// import { File } from '@ionic-native/file/ngx';
// import { Lesson } from "../../models/Lesson";
// import { MediaModel } from "../../models/elements/MediaModel";


@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor(
    private nativeStorage: Storage,
    private helperService: HelperService,
    // private storage: SecureStorage,
    // private file: File,
    // private sanitizer: DomSanitizer,
    // private platform: Platform,
  ) {
  }

  public setToken(token: string) {
    localStorage.setItem('token', token);
  }

  public getToken(): string {
    let token = localStorage.getItem('token');
    if (!token) {
      token = "";
    }
    return token
  }

  public removeToken() {
    localStorage.removeItem('token');
  }

  //set by native app
  public getIsNativeApp(): boolean {
    let string = localStorage.getItem('mobile_app');
    if(string && string == "1"){
      return true
    }else{
      return false
    }
  }

  public setUserId(id: number) {
    localStorage.setItem('userid', String(id));
  }

  public getUserId(): number {
    let id = parseInt(localStorage.getItem('userid'));
    if (!id) {
      id = -1;
    }
    return id
  }

  public removeUserId() {
    localStorage.removeItem('userid');
  }

  public setUserEmail(email: string) {
    localStorage.setItem('userMail', email);
  }

  public getUserEmail(): string {
    let email = localStorage.getItem('userMail');
    if (!email) {
      email = "";
    }
    return email
  }

  public removeUserEmail() {
    localStorage.removeItem('userMail');
  }

  public setLocalUser(id: number, password: string) {
    let salt = bcrypt.genSaltSync(10);
    let hashedPassword = bcrypt.hashSync(password, salt);
    localStorage.setItem(id + "", hashedPassword);
  }

  //get hashed password of user if user exists
  public getLocalUser(id: number): string {
    let pw = localStorage.getItem(id + "");
    if (!pw) {
      pw = "";
    }
    return pw
  }

  public removeLocalUser() {
    localStorage.removeItem(this.getUserId() + "");
  }

  public setTheme(theme: string) {
    localStorage.setItem('theme', theme);
  }

  public getTheme(): string {
    let theme = localStorage.getItem('theme');
    if (!theme) {
      theme = "0";
    }
    return theme
  }

  public setBackground(background: string) {
    localStorage.setItem('background', background);
  }

  public getBackground(): string {
    let background = localStorage.getItem('background');
    if (!background) {
      background = "0";
    }
    return background
  }

  public setAppLanguage(language: string) {
    localStorage.setItem('appLanguage', language);
  }

  public getAppLanguage(): string {
    let language = localStorage.getItem('appLanguage');
    if (!language) {
      language = "de" //could use this.translate.getBrowserLang();
      this.setAppLanguage("de");
    }
    return language
  }

  public setDeviceTokenId(id: string) {
    localStorage.setItem('devicetoken', String(id));
  }

  public getDeviceTokenId(): number {
    let id = parseInt(localStorage.getItem('devicetoken'));
    if (!id) {
      id = -1;
    }
    return id
  }

  public removeDeviceTokenId() {
    localStorage.removeItem('devicetoken');
  }

  /**
   * @method saveAnswersLocally
   * @param lessonId
   * @param instanceId (for diary diaryInstanceId is used)
   * @param isDiary
   * @param answers
   * @param page
   *
   * save answers of a lesson into secure storage (encrypted)
   * @param instanceId
   */
  public async setQuicksavedAnswers(lessonId: number, instanceId: number, isDiary: boolean, answers: Array<Answer>, page: number) {
    let userEmail = this.getUserEmail();
    let data = {
      answers: answers,
      page: page
    }
    let encryptedData = this.helperService.encrypt(JSON.stringify(data), userEmail);
    return new Promise((resolve, reject) => {
      this.nativeStorage.set(userEmail + ':lesson' + lessonId + ':quicksavedAnswers' + instanceId + ':instance' + isDiary + 'isDiary', encryptedData).then((successful) => {
        let decryptedData = this.helperService.decrypt(successful, userEmail);
        resolve(successful);
      }, (error) => {
        reject(error);
      });
    });
  }

  /**
   * @method getQuicksavedAnswers
   * @param lessonId
   * @param instanceId (for diary diaryInstanceId is used)
   * @return Promise for array of answers. Each entry in the array consists of a label and an answer. The label
   * marks the question the answer belongs to.
   *
   * return answers of a lesson the user has quicksaved
   */
  public async getQuicksavedAnswers(lessonId: number, instanceId: number, isDiary: boolean): Promise<any> {
    let userEmail = this.getUserEmail();
    return await this.nativeStorage.get(userEmail + ':lesson' + lessonId + ':quicksavedAnswers' + instanceId + ':instance' + isDiary + 'isDiary').then((data) => {
      let decryptedData = this.helperService.decrypt(data, userEmail);
      return JSON.parse(decryptedData);
    }).catch((error) => {
      return [];
    });
  }

  /**
   * @method removeQuicksavedAnswers
   * @param lessonId
   *
   * remove quicksavings of a lesson.
   */
  public async removeQuicksavedAnswers(lessonId: number, instanceId: number, isDiary: boolean) {
    let userEmail = this.getUserEmail();
    return new Promise((resolve, reject) => {
      try {
        this.nativeStorage.remove(userEmail + ':lesson' + lessonId + ':quicksavedAnswers' + instanceId + ':instance' + isDiary + 'isDiary').then((data) => {
          resolve(data);
        }, (error) => {
          reject(error);
        });
      } catch (exception) {
        reject(exception);
      }
    });
  }

  /**
   *
   * @param lessonId
   * @param instanceId
   * @param currentPage
   * @param totalPages
   *
   * saves current quicksaved lesson progress through page count into local storage
   */
  public async setLessonProgress(lessonId: number, instanceId: number, currentPage: number, totalPages: number) {
    let userEmail = this.getUserEmail();
    let savedDate = new Date();
    let data = {
      currentPage: currentPage,
      totalPages: totalPages,
      savedDate: savedDate
    }
    let encryptedData = this.helperService.encrypt(JSON.stringify(data), userEmail);
    return new Promise((resolve, reject) => {
      this.nativeStorage.set(userEmail + ':lesson' + lessonId + ':progress' + instanceId + ':instance', encryptedData).then((successful) => {
        let decryptedData = this.helperService.decrypt(successful, userEmail);
        resolve(successful);
      }, (error) => {
        reject(error);
      });
    });
  }

  /**
   *
   * @param lessonId
   * @param instanceId
   * @returns Promise for array of pages count
   *
   * Returns current page progress of quicksaved answers
   */
  public async getLessonProgress(lessonId: number, instanceId: number): Promise<any> {
    let userEmail = this.getUserEmail();
    return await this.nativeStorage.get(userEmail + ':lesson' + lessonId + ':progress' + instanceId + ':instance').then((data) => {
      let decryptedData = this.helperService.decrypt(data, userEmail);
      return JSON.parse(decryptedData);
    }).catch((error) => {
      return [];
    });
  }

  /**
   *
   * @param lessonId
   * @param instanceId
   *
   * removes current quicksaved page progress
   */
  public async removeLessonProgress(lessonId: number, instanceId: number) {
    let userEmail = this.getUserEmail();
    return new Promise((resolve, reject) => {
      try {
        this.nativeStorage.remove(userEmail + ':lesson' + lessonId + ':progress' + instanceId + ':instance').then((data) => {
          resolve(data);
        }, (error) => {
          reject(error);
        });
      } catch (exception) {
        reject(exception);
      }
    });
  }

  /*
  to use secure storage replace
  await this.storageReady();
  return await this.secureStorageUser.get(email).then((data) => {

  private secureStorageUser: SecureStorageObject;

  storageReady() {
      return new Promise((resolveReady, rejectReady) => {
          let promiseUsers = new Promise(async (resolve, reject) => {
              if (this.secureStorageUser == null) {
                  await this.storage.create('users').then((secureStorage: SecureStorageObject) => {
                      this.secureStorageUser = secureStorage;
                      resolve();
                  }).catch((error) => {
                      reject(error);
                  });
              } else {
                  resolve();
              }
          });

          Promise.all([
              promiseUsers,
          ]).then(() => {
              resolveReady();
          }, (error) => {
              rejectReady(error);
          });
      });
  }
  */

  // methods to save lesson & media locally
  //
  // /**
  //  * @method saveLessonLocally
  //  * @param lesson
  //  *
  //  * Save a LessonModel to local storage.
  //  */
  //  saveLessonLocally(lesson: Lesson) {
  //     // encrypt lesson and save as JSON in secure storage
  //     return new Promise(async (resolve, reject) => {
  //         // media elements are saved as files on the device. The uri for the element is laoded from the storage.
  //         // this uri is to big to be saved again, so it will be deleted. When loading the lesson again, the uri gets
  //         // reset. After successfully saving, the uris are set back so the currently used model keeps its data.
  //         let localUris: { webUri: string, uri: SafeUrl }[] = [];
  //         for (let element of lesson.elements){
  //             if (element instanceof MediaModel) {
  //                 localUris.push({webUri: element.webUri, uri: element.localUri});
  //                 element.localUri = '';
  //             }
  //         }
  //         let encryptedData = this.helperService.encrypt(JSON.stringify(lesson), this.getUserEmail());
  //         let language = "de"; //use current
  //
  //         this.nativeStorage.set('lesson:' + lesson.id + ':' + language, JSON.stringify(lesson)).then((successful) => {
  //           for (let element of lesson.elements) {
  //             if (element instanceof MediaModel) {
  //               for (let localUri of localUris) {
  //                 if (localUri.webUri == element.webUri) {
  //                   element.localUri = localUri.uri;
  //                 }
  //               }
  //             }
  //           }
  //           resolve(successful);
  //         }).catch((error) => {
  //           for (let element of lesson.elements) {
  //             if (element instanceof MediaModel) {
  //               for (let localUri of localUris) {
  //                 if (localUri.webUri == element['label']) {
  //                   element.localUri = localUri.uri;
  //                 }
  //               }
  //             }
  //           }
  //           reject(error);
  //         });
  //     });
  // }
  //
  // /**
  //  * @method getLocalLesson
  //  * @param lessonId
  //  * @return Promise for a LessonModel containing a specific lesson.
  //  *
  //  * Load a lesson from local Storage.
  //  */
  // async getLocalLesson(lessonId: number): Promise<Lesson> {
  //   // get intervention entry
  //   let lesson: Lesson = await this.nativeStorage.get('lesson:' + lessonId + ':' + this.getAppLanguage()).then(async (lessonString) => {
  //     // decrypt lesson and parse into LessonModel
  //     let decryptedData = this.helperService.decrypt(lessonString, this.getUserEmail());
  //     lesson = JSON.parse(decryptedData);
  //     let lessonLoaded = JSON.parse(lessonString);
  //
  //     // load media file for each media element and set uri accordingly
  //     for (let page of lessonLoaded.elements) {
  //       for (let element of page.questions) {
  //         if (element.type == QuestionTypes.MEDIA) {
  //           if (element.questionModel['localUri'] == '') {
  //             element.questionModel['localUri'] = await this.loadMediaFile(element.questionModel['filePath'], element.questionModel['fileName'], element.type['type']).then((url) => {
  //               return url;
  //             }).catch(() => {
  //               return element.questionModel['webUri'];
  //             });
  //           }
  //         }
  //       }
  //     }
  //     return lessonLoaded;
  //   }).catch((error) => {
  //     throw('could not load local lesson');
  //   });
  //   return lesson;
  // }
  //
  // /**
  //  * @method saveMediaFile
  //  * @param filePath
  //  * @param fileName
  //  * @param blob
  //  * @param replace
  //  *
  //  * create a file for a media component on the device
  //  */
  //   saveMediaFile(filePath: string, fileName: string, blob: Blob, replace: boolean) {
  //     return new Promise(async (resolve, reject) => {
  //         if (!this.platform.is('android') && !this.platform.is('ios')) {
  //             // return the online url for browser module since browser cannot save media locally
  //             resolve('browser');
  //         }
  //         await this.file.writeFile(filePath, fileName, blob, {replace: replace}).then(() => {
  //             resolve();
  //         }).catch((error) => {
  //             reject(error);  //Rethrow - will be caught by caller
  //         });
  //
  //     });
  // }
  //
  // /**
  //  * @method loadMediaFile
  //  * @param filePath
  //  * @param fileName
  //  * @param type
  //  * @return Promise<SafeUrl> for Media File
  //  *
  //  * creates a Safe Url for a saved media file which can then be used as source by html tags.
  //  */
  // async loadMediaFile(filePath: string, fileName: string, type: MediaType): Promise<SafeUrl> {
  //   if (!this.platform.is('android') && !this.platform.is('ios')) {
  //     // return the online url for browser module since browser cannot save media locally
  //     throw ('browser');
  //   }
  //   if (!this.file.checkFile(filePath, fileName)) {
  //     throw('file does not exist');
  //   }
  //   let fileType: string;
  //   switch (type) {
  //     case (MediaType.IMAGE_JPEG):
  //       fileType = 'image/jpg';
  //       break;
  //     case (MediaType.IMAGE_PNG):
  //       fileType = 'image/png';
  //       break;
  //     case (MediaType.IMAGE_GIF):
  //       fileType = 'image/gif';
  //       break;
  //     case (MediaType.AUDIO_WEBM):
  //       fileType = 'audio/wemb';
  //       break;
  //     case (MediaType.AUDIO_OGG):
  //       fileType = 'audio/ogg';
  //       break;
  //     case (MediaType.VIDEO_WEBM):
  //       fileType = 'video/webm';
  //       break;
  //     case (MediaType.VIDEO_OGG):
  //       fileType = 'video/ogg';
  //       break;
  //     default:
  //       throw('media type does not exist.');
  //   }
  //   return await this.file.readAsArrayBuffer(filePath, fileName).then((result) => {
  //     let blob = new Blob([result], {type: fileType});
  //     let urlCreator = window.URL;
  //     return this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(blob));
  //   });
  // }
}
