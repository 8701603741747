<div [class.required]="model.required" class="question">
  <ion-item [class.answerRequired]="model.highlight && (currentValue == null || currentValue.length == 0)" class="ion-no-padding ion-text-wrap" lines="none">
    <ion-label class="ion-text-wrap" style="opacity: 1.0">
      {{model.questionResolved}}
    </ion-label>
    <ion-select *ngIf="!loading" #selectRef multiple="true" [value]="currentValue" okText='{{ "BUTTONS.SAVE" | translate }}' cancelText='{{ "BUTTONS.CANCEL" | translate }}'
        [disabled]="model.isDisabled" (ionChange)="emitAnswer($event.detail.value)">
      <ion-select-option *ngFor="let option of model.options; let i = index" [value]="model.values[i]">
        {{model.optionsResolved[i]}}
      </ion-select-option>
    </ion-select>
  </ion-item>
</div>
