import {SafeHtml} from "@angular/platform-browser";
import {ElementModel} from "./ElementModel";

export class HeadlineModel extends ElementModel {
  resolvable = ['headline'];
  _headlineResolved: SafeHtml;
  headline: string;
  color: string;

  constructor(position: number, headline: string, color: string) {
    super(position);
    this.headline = headline;
    this.color = color;
  }

  public set headlineResolved(value: string) {
    this._headlineResolved = this.lesson.sanitizer.bypassSecurityTrustHtml(value);
  }
}
