import {QuestionModel} from "./QuestionModel";

export class QuestionSliderModel extends QuestionModel {
  labelLeft: string = "";
  labelRight: string = "";
  scaleRangeLeft: number = 0;
  scaleRangeRight: number = 1;
  stepWidth: number = 1;
  start: number;

  constructor(position: number, question: string, required: boolean, label: string, labelLeft: string, labelRight: string, scaleRangeLeft: number,
      scaleRangeRight: number, stepWidth: number, start: number) {
    super(position, question, required, label);
    this.labelLeft = labelLeft;
    this.labelRight = labelRight;
    this.scaleRangeLeft = scaleRangeLeft;
    this.scaleRangeRight = scaleRangeRight;
    this.stepWidth = stepWidth;
    this.start = start ? start : scaleRangeLeft;
  }
}
