import { Component } from '@angular/core';
import { Element_ } from "../Element_";
import { HeadlineModel } from "../../../models/elements/HeadlineModel";
@Component({
  selector: 'app-headline',
  templateUrl: './headline.component.html',
  styleUrls: ['./headline.component.scss'],
})
export class Headline extends Element_ {
  public model;

  constructor() {
    super();
    this.model = this.model as HeadlineModel;
  }
}
