export class User{
  id: number;
  name: string;
  firstname: string;
  lastname: string;
  settings: UserSettings;

  constructor(id: number, name: string, firstname: string, lastname: string, settings: UserSettings) {
    this.id = id;
    this.name = name;
    this.firstname = firstname;
    this.lastname = lastname;
    this.settings = settings;
  }
}

export interface UserSettings {
  background: string,
  theme: string,
  language: string,
  locale: string
}
