import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { File } from '@ionic-native/file/ngx';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AutosizeModule } from 'ngx-autosize';
import { IonicStorageModule } from '@ionic/storage';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng2FlatpickrModule } from "ng2-flatpickr";
import { FCM } from '@ionic-native/fcm/ngx'
// import { AngularFireModule } from '@angular/fire/compat';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from "./components/components.module";
import { AlertService } from './providers/alert/alert.service';
import { FirebaseService } from './providers/firebase/firebase.service';
import { HelperService } from "./providers/helper/helper.service";
import { LocalStorageService } from './providers/local-storage/local-storage.service';
import { ParserService } from "./providers/parser/parser.service";
import { RequestProviderService } from './providers/request-provider/request-provider.service';
import { TokenInterceptorService } from "./providers/token-interceptor/token-interceptor.service";
import { environment, firebaseConfig } from '../environments/environment';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    CommonModule,
    BrowserModule,
    ComponentsModule,
    HttpClientModule,
    IonicModule.forRoot({swipeBackEnabled: false}),
    // AngularFireModule.initializeApp(firebaseConfig),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    AutosizeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    BrowserAnimationsModule,
    Ng2FlatpickrModule,
    FontAwesomeModule,
  ],
  providers: [
    StatusBar,
    HttpClientModule,
    SplashScreen,
    File,
    HttpClientModule,
    FCM,
    AlertService,
    FirebaseService,
    HelperService,
    LocalStorageService,
    ParserService,
    RequestProviderService,
    TokenInterceptorService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule {
  public darkTheme = true;
  constructor() { }
}
