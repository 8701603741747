<ng-container *ngIf="model">
  <div [attr.id]="'element' + model.position + '-' + loopIndex">
    <app-headline *ngIf="type === 'HeadlineModel'" [init]="model"></app-headline>

    <app-text *ngIf="type === 'TextModel'" [init]="model"></app-text>

    <app-media *ngIf="type === 'MediaModel'" [init]="model"></app-media>

    <app-yes-no *ngIf="type === 'QuestionYesNoModel'" [init]="model" [loopIndex]="loopIndex"></app-yes-no>

    <app-single-choice *ngIf="type === 'QuestionSingleChoiceModel'" [init]="model" [loopIndex]="loopIndex"></app-single-choice>

    <app-multiple-choice *ngIf="type === 'QuestionMultipleChoiceModel'" [init]="model" [loopIndex]="loopIndex"></app-multiple-choice>

    <app-slider *ngIf="type === 'QuestionSliderModel'" [init]="model" [loopIndex]="loopIndex"></app-slider>

    <app-text-date *ngIf="type === 'QuestionDateModel'" [init]="model" [loopIndex]="loopIndex"></app-text-date>

    <app-text-string *ngIf="type === 'QuestionTextStringModel'" [init]="model" [loopIndex]="loopIndex"></app-text-string>

    <app-text-area *ngIf="type === 'QuestionTextAreaModel'" [init]="model" [loopIndex]="loopIndex"></app-text-area>

    <app-questiontable *ngIf="type === 'QuestionTableModel'" [init]="model" [loopIndex]="loopIndex"></app-questiontable>

    <app-line *ngIf="type === 'LineModel'" [init]="model"></app-line>

    <app-space *ngIf="type === 'SpaceModel'" [init]="model"></app-space>

    <app-block *ngIf="type === 'BlockModel'" [init]="model" [loopIndex]="loopIndex"></app-block>
  </div>
</ng-container>
