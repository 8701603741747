import {Component} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {HttpResponse} from '@angular/common/http';
import {RequestProviderService} from "../../../providers/request-provider/request-provider.service";
import {AlertService} from "../../../providers/alert/alert.service";

@Component({
    selector: 'app-delete-account',
    templateUrl: './delete-account.component.html',
    styleUrls: ['./delete-account.component.scss'],
})
export class DeleteAccountComponent {
  public password: string = "";
  public hide = true;
  public usePseudonymized: boolean;

  constructor(
    private modalController: ModalController,
    private requestProvider: RequestProviderService,
    private alertService: AlertService,
  ) {
  }

  onCloseDialog() {
    this.modalController.dismiss({
      'requested': false
    });
  }

  requestDeletion() {
    this.requestProvider.requestDeleteAccount(this.password, this.usePseudonymized).subscribe(async (response) => {
      if (response instanceof HttpResponse && response.status == 201) {
        await this.alertService.presentToast('SUCCESS.SUCCESS_ACCOUNT_DELETION_REQUESTED');
        this.modalController.dismiss({
          'requested': true
        });
      }
    },(error) => {
      if(error.status === 422 && JSON.stringify(error).includes("user")){
        this.alertService.showError('ERROR.ERROR', 'ERROR.ERROR_ACCOUNT_DELETION_ALREADY_REQUESTED');
      }else if(error.status === 422 && JSON.stringify(error).includes("password")){
        this.alertService.showError('ERROR.ERROR', 'ERROR.ERROR_PASSWORD_WRONG');
      }else{
        this.alertService.showError('ERROR.ERROR', 'ERROR.ERROR_SOMETHING_WENT_WRONG');
      }
    })
  }
}
