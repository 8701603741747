<ion-buttons style="justify-content: flex-end;">
  <ion-button id="closeButton" (click)="onCloseDialog()">
    <ion-icon name="close-circle-outline" size="large"></ion-icon>
  </ion-button>
</ion-buttons>

<div class="dialog-content">
  <h3>
    {{ 'PROFILE.REQUEST_DELETE_ACCOUNT' | translate }}
  </h3>
  <p>
    {{ 'PROFILE.REQUEST_DELETE_ACCOUNT_TEXT' | translate }}
  </p>

  <ion-item>
    <ion-label>
      {{"PROFILE.REQUEST_DELETE_ACCOUNT_DATA" | translate}}
    </ion-label>
    <ion-select [(ngModel)]="usePseudonymized" [value]="-1" id="inputDeletionType" okText='{{ "BUTTONS.SAVE" | translate }}' cancelText='{{ "BUTTONS.CANCEL" | translate }}'>
      <ion-select-option [value]="true" id="inputDeletionTypePseudo">
        {{"PROFILE.REQUEST_DELETE_ACCOUNT_PSEUDONYMIZE" | translate}}
      </ion-select-option>
      <ion-select-option [value]="false" id="inputDeletionTypeDelete">
        {{"PROFILE.REQUEST_DELETE_ACCOUNT_DELETE_DATA" | translate}}
      </ion-select-option>
    </ion-select>
  </ion-item>

  <ion-item class="item">
    <ion-label position="floating" class="font">
      {{"LOGIN_REGISTRATION.PASSWORD" | translate}}
    </ion-label>
    <ion-input [type]="hide ? 'password' : 'text'" [(ngModel)]="password" name="password" class="font" id="inputPWDeletion" required>
    </ion-input>
    <a class="suffix-icon" (click)="hide = !hide">
      <ion-icon color="primary" [name]="hide ? 'eye-off' : 'eye'" slot="end"></ion-icon>
    </a>
  </ion-item>
</div>

<ion-buttons class="dialog-actions">
  <ion-button class="dialog-action" color="primary" fill="solid" [disabled]="password == '' || (usePseudonymized != false && usePseudonymized != true)" id="requestDeletionButton" (click)="requestDeletion()">
    {{'PROFILE.REQUEST_DELETE_ACCOUNT_BUTTON' | translate}}
  </ion-button>
</ion-buttons>
