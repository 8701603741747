import {DiaryInstance} from "./DiaryInstance";
import {ElementModel} from "./elements/ElementModel";

export class Lesson {
  id: number;
  title: string;
  studyId: number;
  description: string;
  answersheetId: number;
  answersheetVersion: string;
  questions_required: boolean;
  finished: boolean = false;
  elements: Array<ElementModel>;
  defaultColor: string
  answersLesson: boolean = false;
  conditional: boolean = false;
  afterPrevious: boolean = false;
  unlocked: boolean = false;
  unlock_diaries: Array<number>;
  diaryID: number;
  skills: Array<number> = [];
  // can be set during runtime if required
  diaryInstances: DiaryInstance[] = [];
  bonusLesson: boolean = true;
  // quicksaved completion progress and saveDate (set manually from localstorage during runtime if required)
  completionProgress: number;
  savedDate: Date;

  constructor(id: number, title: string, description: string, finished: boolean, defaultColor: string, unlock_diaries: Array<number>,
      questions_required: boolean, diaryID: number, studyId: number, skills: Array<number>) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.finished = finished;
    this.defaultColor = defaultColor;
    this.unlock_diaries = unlock_diaries;
    this.questions_required = questions_required;
    this.diaryID = diaryID;
    this.studyId = studyId;
    this.skills = skills;
  }
}
