import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SUPPORT_MAIL } from '../../../../environments/environment';

@Component({
  selector: 'app-technical-support',
  templateUrl: './technical-support.component.html',
  styleUrls: ['./technical-support.component.scss'],
})
export class TechnicalSupportComponent {
  public supportMail = SUPPORT_MAIL;

  constructor(
    private modalController: ModalController,
    private translateService: TranslateService
  ) {
  }

  onCloseDialog() {
    this.modalController.dismiss();
  }

  onOpenEmailClient() {
    window.location.href = 'mailto:' + this.supportMail + '?subject=' + this.translateService.instant('SUPPORT.TECHNICAL_SUPPORT');
  }
}
