import { Component, OnInit } from '@angular/core';
import { faFilePowerpoint, faFileWord, faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { Element_ } from "../Element_";
import { MediaType } from "../../../../app/models/elements/Enums";
import { MediaModel } from "../../../models/elements/MediaModel";

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
  host: { '[class.banner]': 'this.model.banner' }
})

export class Media extends Element_ implements OnInit {
  faIcon;
  model: MediaModel;

  constructor() {
    super();
    this.model = this.model as MediaModel;
  }

  ngOnInit() {
    if (MediaType.isExcel(this.model.type)) {
      this.faIcon = faFileExcel;
    } else if (MediaType.isWord(this.model.type)) {
      this.faIcon = faFileWord;
    } else if (MediaType.isPdf(this.model.type)) {
      this.faIcon = faFilePdf;
    } else if (MediaType.isPowerpoint(this.model.type)) {
      this.faIcon = faFilePowerpoint;
    }
  }

  get MediaType() {
    return MediaType;
  }
}
