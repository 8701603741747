import { QuestionModel } from "./QuestionModel";

export class QuestionTableModel extends QuestionModel {
  minVal: number = 0;
  maxVal: number = 1;
  answerTexts: Array<String> = [];

  constructor(position: number, question: string, required: boolean, label: string, answers: string[], minVal: number, maxVal: number) {
      super(position, question, required, label);
      this.minVal = minVal;
      this.maxVal = maxVal;
      this.answerTexts = answers;
  }
}
