<div [class.required]="model.required" class="ion-text-xl-left ion-text-lg-left ion-text-md-left ion-text-sm-left question">
  <ion-item [class.answerRequired]="model.highlight && currentValue == null" class="ion-align-items-center ion-text-wrap ion-no-padding" lines="none">
    <ion-label class="ion-text-wrap" style="opacity: 1.0">
      {{model.questionResolved}}
    </ion-label>
    <ion-select *ngIf="!loading" #selectRef multiple="false" [value]="currentValue" okText='{{ "BUTTONS.SAVE" | translate }}' cancelText='{{ "BUTTONS.CANCEL" | translate }}'
        [disabled]="model.isDisabled" (ionChange)="emitAnswer($event.detail.value)">
      <ion-select-option *ngFor="let option of model.options; let i = index" [value]="model.values[i]">
        {{model.optionsResolved[i]}}
      </ion-select-option>
    </ion-select>
  </ion-item>
</div>
