import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { FEATURES } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SkillFeatureGuard implements CanActivate {
  private features = FEATURES;

  constructor(
    private router: Router,
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this.features.includes("skills")){
      return of(true);
    }else{
      this.router.navigateByUrl('/home');
      return of(false);
    }
  }
}
