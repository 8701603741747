import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ModalController, NavParams} from "@ionic/angular";
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {Thread} from '../../../models/Thread';
import {ChatInformationComponent} from '../chat-information/chat-information.component';
import {ParserService} from "../../../providers/parser/parser.service";
import {RequestProviderService} from "../../../providers/request-provider/request-provider.service";
import {LocalStorageService} from '../../../providers/local-storage/local-storage.service';
import {AlertService} from '../../../providers/alert/alert.service';

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
    @Input() thread: Thread;
    @ViewChild('chatContentRef') chatContentRef;

    private myUserId: number;
    public newMsg: string;

    constructor(
      private navParams: NavParams,
      private modalController: ModalController,
      private parserService: ParserService,
      private requestProvider: RequestProviderService,
      private localStorage: LocalStorageService,
      private alertService: AlertService,
    ) {
      navParams.get('thread');
    }

    ngOnInit() {
      this.myUserId = this.localStorage.getUserId();

      setTimeout(() => {this.chatContentRef.scrollToBottom(0)}, 500);

      if(this.thread.lessonFeedbackRequired && !this.thread.read){
        let progressJSON = {
          "data": {
            "type": "intervention_instance",
            "attributes": {
              "progress": {
                "feedback_provided": {
                  "questionnaire_id": this.thread.lessonId,
                  "thread_id": this.thread.id,
                  "read": true,
                  "answersheet_id": this.thread.answersheetId
                }
              }
            }
          }
        };

        this.requestProvider.setFeedbackRead(this.thread.assignedInstanceID, progressJSON).pipe(map((result) => result), catchError(error => {return throwError(error)}))
          .subscribe((response) => {
            if (response instanceof HttpErrorResponse) {
              //feedback can not be set to read, try to set thread read by requesting thread details
              this.getNewMessages(true);
            }else{
              this.thread.read = true;
              this.parserService.newFeedback--;
            }
          }, (error) => {
            //feedback can not be set to read, try to set thread read by requesting thread details
            this.getNewMessages(true);
          });
      }else{
        if(!this.thread.read){
          this.parserService.newFeedback--;
        }
        this.thread.read = true;
      }
    }

    dismiss() {
      this.modalController.dismiss({
        'dismissed': true
      });
    }

    /**
     * @method sendMessage
     *
     * Sends the message which is contained in the input field. Sends it into the
     * thread which is currently active. Then pulls the messages of the chat
     * (Sent by the user), pushes them into view and scrolls down.
     */
    sendMessage() {
      this.requestProvider.sendMessage(this.newMsg, this.thread.id).pipe(map((result) => result), catchError(error => {return throwError(error)}))
        .subscribe((response) => {
          this.newMsg = '';
          this.getNewMessages(false);
        }, (error) => {
          this.alertService.showError('ERROR.ERROR', 'ERROR.ERROR_SEND_MESSAGE');
        });
    }

    getNewMessages(readFeedback: boolean){
      this.requestProvider.getMessageThread(this.thread.id).pipe(map((result) => result), catchError(error => {return throwError(error)}))
        .subscribe((response) => {
          this.thread = this.parserService.parseThread(response);
          setTimeout(() => {this.chatContentRef.scrollToBottom(300)}, 500);
        }, (error) => {
          this.handleErrorGetMessages(readFeedback);
        });
    }

    handleErrorGetMessages(readFeedback: boolean){
      if(readFeedback){
        this.alertService.showError('ERROR.ERROR', 'ERROR.ERROR_READ_FEEDBACK');
      }else{
        this.alertService.showError('ERROR.ERROR', 'ERROR.ERROR_DATA_LOADING');
      }
    }

    /**
     * @method presentChatInformation
     *
     * Shows available information about the current thread via a modal.
     */
    async presentChatInformation() {
      const modal = await this.modalController.create({
        component: ChatInformationComponent,
        componentProps: {
          'users': this.thread.participants,
        }
      });
      return await modal.present();
    }

    scrollBottomSlow() {
      this.chatContentRef.scrollToBottom(300);
    }

    getOffsetSize(message) {
      if(message.authorId != this.myUserId){
        return 0
      }else{
        return 3
      }
    }
}
