import {QuestionModel} from "./QuestionModel";

export class QuestionDateModel extends QuestionModel {
  type: string;

  constructor(position: number, question: string, required: boolean, label: string, type: string) {
    super(position, question, required, label);
    this.type = type;
  }
}
