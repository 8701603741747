export enum QuestionTypes {
  YES_NO, SINGLE_CHOICE, MULTIPLE_CHOICE, SAM_SCALE_FACE, SAM_SCALE_BODY, SLIDER,
  TEXT_AREA, TEXT_STRING, TEXT_DATE, HEADLINE, TEXT, MEDIA, BLOCK_OPENS, BLOCK_CLOSES, PAGE_CONDITIONAL,
  SPACE, LINE
}

export enum MediaType {
  IMAGE_PNG, IMAGE_JPEG, IMAGE_GIF, IMAGE_SVG, AUDIO_MPEG, AUDIO_WAV, AUDIO_M4A, VIDEO_MP4, APPLICATION_DOC, APPLICATION_DOCX,
  APPLICATION_PPT, APPLICATION_PPTX, APPLICATION_ODT, APPLICATION_PDF,
  NOT_SUPPORTED
}

export namespace MediaType {
  export function isApplication(media: MediaType): boolean {
    return (media == MediaType.APPLICATION_DOC || media == MediaType.APPLICATION_DOCX ||
      media == MediaType.APPLICATION_PPT || media == MediaType.APPLICATION_PPTX ||
      media == MediaType.APPLICATION_ODT || media == MediaType.APPLICATION_PDF);
  }

  export function isAudio(media: MediaType): boolean {
    return (media == MediaType.AUDIO_MPEG || media == MediaType.AUDIO_WAV || media == MediaType.AUDIO_M4A);
  }

  export function isImage(media: MediaType): boolean {
    return (media == MediaType.IMAGE_PNG || media == MediaType.IMAGE_JPEG || media == MediaType.IMAGE_GIF || media == MediaType.IMAGE_SVG);
  }

  export function isVideo(media: MediaType): boolean {
    return (media == MediaType.VIDEO_MP4);
  }

  export function isWord(media: MediaType): boolean {
    return (media === MediaType.APPLICATION_DOC || media === MediaType.APPLICATION_DOCX);
  }

  export function isExcel(media: MediaType): boolean {
    return (media === MediaType.APPLICATION_ODT);
  }

  export function isPowerpoint(media: MediaType): boolean {
    return (media === MediaType.APPLICATION_PPT || media === MediaType.APPLICATION_PPTX);
  }

  export function isPdf(media: MediaType): boolean {
    return (media === MediaType.APPLICATION_PDF);
  }
}

export enum TextType {
  NONE, IMPORTANT, TIP, INFO, SUCCESS, HIGHLIGHT, TABLE
}
export enum BlockType {
  NONE, DETAILS, CONDITIONAL, BOTH
}
