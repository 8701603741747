import { Component, Input, OnInit } from '@angular/core';
import { Question } from "../Question";
import { QuestionTableModel } from "../../../models/elements/QuestionTableModel";

@Component({
  selector: 'app-questiontable',
  templateUrl: './questiontable.component.html',
  styleUrls: ['./questiontable.component.scss'],
  host: { 'class': 'question' }
})

export class QuestionTable extends Question implements OnInit{
  @Input() public loopIndex: number;
  public model: QuestionTableModel;
  public questionTexts: String[];
  value: Array<number> = null;

  constructor() {
    super();
  }

  ngOnInit(){
    this.questionTexts = JSON.parse(this.model.question);
    this.value = new Array(this.questionTexts.length).fill(null);
  }

  public ngAfterViewInit(): void {
    // emit null (so that there is an entry for each repetition) or saved answer
    // in this case Array with null entries for all values
    let currentVal = this.currentValue;
    if(currentVal == null){
      currentVal = new Array(this.questionTexts.length).fill(null);
    }
    this.value = currentVal;
    this.emitAnswer();
  }

  ngOnDestroy(){
    console.log("destroy pos " + this.model.position + " loop" +  this.loopIndex)
    this.model.lesson.removeAnswerRepetition(this.model.position, this.loopIndex);
  }

  public emitAnswer() {
    if (this.model.lesson.loading) {
      return
    }else if(this.value.includes(undefined)){
      this.value = this.value.map(v => v === undefined ? null : v);
    }
    // insert technical answer in global array
    this.model.lesson.insertAnswer(this.model.position, this.loopIndex, this.value);
    this.value = this.currentValue;
  }

  get numberOfValues(): number {
    return (this.model.maxVal - this.model.minVal + 1)
  }

  get currentValue(): Array<number> {
    return this.model.lesson.getAnswer(this.model.position, this.loopIndex)
  }
}
