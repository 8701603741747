import {ElementModel} from "./ElementModel";

export abstract class QuestionModel extends ElementModel {
  resolvable = ['question']
  question: string;
  questionResolved: string;
  required: boolean;
  highlight: boolean;
  label: string;

  constructor(position: number, question: string, required: boolean, label: string) {
    super(position);
    this.question = question;
    this.required = required;
    this.label = label;
  }
}
