<div class="question-media question" [class.banner]="model.banner">
  <div [class.banner-headline]="model.banner">
    <h4 class="ion-text-left">
      {{model.subtitleResolved}}
    </h4>
  </div>
  <img *ngIf="MediaType.isImage(model.type)" [src]="model.localUri" alt="picture not available. Please try again." [style.width.%]="model.width">
  <!-- Use video player instead of html video if platform is android -->

  <div *ngIf="MediaType.isVideo(model.type)" class="ion-text-left">
    <video *ngIf="!model.isAudio" [src]="model.localUri" controls class="mediaplayer" [style.width.%]="model.width"></video>
    <audio *ngIf="model.isAudio" [src]="model.localUri" controls class="mediaplayer"></audio>
  </div>

  <div *ngIf="MediaType.isAudio(model.type)" class="audioDiv">
    <audio [src]="model.localUri" controls class="mediaplayer"></audio>
  </div>

  <div *ngIf="MediaType.isApplication(model.type)" class="mediaDiv">
    <a href="{{model.webUri}}" target="_blank" rel="noopener noreferrer" download="{{model.filePath}}">
      <fa-icon [icon]="faIcon"></fa-icon>
      {{model.filePath}}
    </a>
  </div>

  <div *ngIf='model.description != ""' [class.banner-headline]="model.banner">
    <p class="ion-text-left">
      {{model.descriptionResolved}}
    </p>
  </div>
</div>
