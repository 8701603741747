import { ElementModel } from "./elements/ElementModel";

export class Skill{
    id: number;
    intervention_id: number;
    title: string;
    color: string;
    icon: string;
    description: Array<ElementModel>;
    study_id: number;

    constructor(id: number, intervention_id: number, title: string, color: string, icon: string, description: Array<ElementModel>, study_id: number){
        this.id = id;
        this.intervention_id = intervention_id;
        this.title = title;
        this.color = color;
        this.icon = icon;
        this.description = description;
        this.study_id = study_id;
    }
}