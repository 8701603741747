<ion-buttons style="justify-content: flex-end;">
  <ion-button id="closeButton" (click)="onCloseDialog()">
    <ion-icon name="close-circle-outline" size="large"></ion-icon>
  </ion-button>
</ion-buttons>
<!-- TODO check status and decide which content to show -->
<!-- jedes dialogData.dailyTaskDone && könnte glaub weggelassen werden -->
<div class="dialog-content">
  <h3>
    {{ 'BUDDY.SUCCESS' | translate }}
  </h3>
  <!-- Beide buddies haben den task erledigt (remind timestamps egal)-->
  <ng-container *ngIf="dialogData.dailyTaskDone && dialogData.dailyTaskDoneBuddy">
    <p>
      {{ 'BUDDY.BOTH_DONE' | translate }}
    </p>
  </ng-container>
  <!-- Ich hab noch nicht erinnert und Buddy hat den task heut nicht erledigt (& nicht reminded aber egal glaub)-->
  <ng-container *ngIf="dialogData.dailyTaskDone && !dialogData.reminded && !dialogData.dailyTaskDoneBuddy">
    <p>
      {{ 'BUDDY.REMIND_TEXT' | translate }}
    </p>
  </ng-container>
  <!-- Ich hab schon erinnert und Buddy hat den task heut nicht erledigt (& nicht reminded aber egal glaub)-->
  <ng-container *ngIf="dialogData.dailyTaskDone && !dialogData.dailyTaskDoneBuddy && dialogData.reminded">
    <p *ngIf="justReminded"> <!-- buddy was reminded right now -->
      {{ 'BUDDY.REMINDED' | translate }}
    </p>

    <p *ngIf="!justReminded"> <!-- was on earlier open -->
      {{ 'BUDDY.ALREADY_REMINDED' | translate }}
    </p>
  </ng-container>
</div>

<div class="dialog-action">
  <!-- TODO dont show sometimes, but the dialog-action div should stay -->
  <ion-button *ngIf="dialogData.dailyTaskDone && !dialogData.reminded && !dialogData.dailyTaskDoneBuddy" id="remindBuddyButton"
      class="dialog-action" color="primary" fill="solid" [disabled]="disableButton" (click)="sendReminderMail()">
    {{ 'BUDDY.REMIND' | translate }}
 </ion-button>
</div>
<canvas class="celebrate" #confettiCanvasRef></canvas>
