import {LessonPage} from "../../pages/lesson/lesson.page";

export abstract class ElementModel {
  elementType: string;
  position: number;
  lesson: LessonPage;
  isDisabled: boolean;
  resolvable: Array<string> = [];

  protected constructor(position: number) {
    this.position = position;
    this.elementType = this.constructor.name;
  }
}
