<div class="ion-text-left question-text question" xmlns="http://www.w3.org/1999/html">
  <div *ngIf="model.type == TextType.NONE" [innerHTML]=model._textResolved>
  </div>

  <div *ngIf="model.type == TextType.HIGHLIGHT" [class.textHighlight]="TextType.HIGHLIGHT">
    <ion-grid>
      <ion-row>
        <ion-col size="12" [innerHTML]=model._textResolved>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <div *ngIf="model.type != TextType.NONE && model.type != TextType.HIGHLIGHT && model.type != TextType.TABLE"
      [class.textImportant]="model.type == TextType.IMPORTANT" [class.textTip]="model.type == TextType.TIP"
      [class.textInfo]="model.type == TextType.INFO" [class.textSuccess]="model.type == TextType.SUCCESS">
    <ion-grid>
      <ion-row>
        <ion-col class="icon-col" size="2" size-md="1">
          <ion-icon [name]="getIcon()" size="large"></ion-icon>
        </ion-col>
        <ion-col size="10" size-md="11" [innerHTML]=model._textResolved>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <div *ngIf="model.type == TextType.TABLE" class="textTable" [ngClass]="{'textTable': true, 'wideTable': isMobile || numberOfColumns > 4}" [innerHTML]="model._textResolved">
  </div>
</div>
