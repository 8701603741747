import {Injectable} from '@angular/core';
import {NavController} from '@ionic/angular';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {BehaviorSubject, from, Observable, Subject, throwError} from 'rxjs';
import {catchError, filter, finalize, switchMap, take, takeUntil} from 'rxjs/operators';
import {RequestProviderService} from '../request-provider/request-provider.service';
import {AlertService} from "../alert/alert.service";
import {LocalStorageService} from "../local-storage/local-storage.service";
import {ParserService} from "../parser/parser.service";

@Injectable({
    providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
    private refreshTokenInProgress = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private cancelPendingRequests$ = new Subject<void>()

    constructor(
      private navCtrl: NavController,
      private requestProvider: RequestProviderService,
      private alertService: AlertService,
      private localStorage: LocalStorageService,
      private parserService: ParserService,
    ) {
    }

    /**
     * @method addAuthToken
     * @param request The current request to the server.
     * @return HttpRequest Containing the added authorization
     *
     * Adds the authorization header to the request using the Token and returns the new request.
     */
    private static addAuthToken(request: HttpRequest<any>): HttpRequest<any> {
      return request.clone({setHeaders: {Authorization: 'Bearer ' + localStorage.getItem('token')}
      // withCredentials: true
      });
    }

    public cancelPendingRequests() {
      this.cancelPendingRequests$.next(null)
    }

    private onCancelPendingRequests() {
      return this.cancelPendingRequests$.asObservable()
    }

    /**
     * @method intercept
     *
     * Gets called before every http request.
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // If outside of certain requests, just add the auth token to the request.
      if (!(request.url.includes('/auth/login') || request.url.includes('/assets/i18n/'))) { //|| request.url.includes('/auth/register')
        request = TokenInterceptorService.addAuthToken(request);
      }

      return next.handle(request).pipe(takeUntil(this.onCancelPendingRequests()), catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          const errorStatus: number = error.status;
          let errorMessage: string = "";
          if(errorStatus != 0){
            errorMessage = JSON.stringify(error.error.errors[0].detail);
          }
          // Token expired
          if ((errorStatus === 401) && (errorMessage === '\"Token has expired\"')) {
            if (this.refreshTokenInProgress) {
              // If token is currently refreshing, wait until refreshToken$ has a non-null value & continue with request if it is
              return this.refreshTokenSubject.pipe(
                filter(result => result != null),
                take(1),
                switchMap(() => next.handle(TokenInterceptorService.addAuthToken(request))));
            } else {
              this.refreshTokenInProgress = true;
              // Set the refreshToken$ to null to discontinue new requests and make them wait
              this.refreshTokenSubject.next(null);
              return from(this.refreshToken()).pipe(switchMap((token) => {
                // Refresh token is completed -> Set the refreshToken to that token and handle request
                this.refreshTokenSubject.next(token);
                return next.handle(TokenInterceptorService.addAuthToken(request));
              }), catchError((err) => {
                return throwError(error);
              }), finalize(() => {
                this.refreshTokenInProgress = false;
              }));
            }
          } else if((errorStatus === 401 && errorMessage === '\"Wrong number of segments\"') ||
              (errorStatus === 400 && errorMessage === '\"The provided token has expired. Re-login to get a new token.\"')) { // Token blacklisted
            this.refreshTokenInProgress = false;
            this.forceLogout();
            this.navCtrl.navigateRoot('/login', {animated: true, animationDirection: 'forward'}); //use this to reset root so that no data of previously logged in user is still there
          } else {
            return throwError(error);
          }
        } else {
          return throwError(error);
        }
      }));
    }

    public async forceLogout() {
      this.parserService.newMessages = 0;
      this.parserService.newFeedback = 0;
      this.localStorage.removeToken();
      this.localStorage.removeUserEmail();
      this.localStorage.removeLocalUser();
      this.localStorage.removeUserId();
      try {
        await this.alertService.dismissAllToasts();
        this.alertService.dismissLoading();
      } catch (e) {}
    }

    private refreshToken() {
      return this.requestProvider.refreshToken().then((response) => {
        if (response instanceof HttpResponse && response.status === 200) {
          const token = response.body.data.attributes.token;
          this.localStorage.setToken(token);
          return token;
        }
      }).catch((error) => {
        this.alertService.showError('ERROR.ERROR', 'ERROR.ERROR_TOKEN_REFRESH');
      });
    }
}
