export class Activity{
  id: number;
  text: string;
  action: string;
  created_at: Date;

  constructor(id: number, text: string, action: string, created_at: Date) {
    this.id = id;
    this.text = text;
    this.action = action;
    this.created_at = created_at;
  }
}
