export class Diary {
  diaryId: number;
  title: string;
  description: string;
  picture: string;
  studyId: number
  lessonId: number

  constructor(diaryId: number, title: string, description: string, picture: string, studyId: number, lessonId: number) {
    this.diaryId = diaryId;
    this.title = title;
    this.description = description;
    this.picture = picture;
    this.studyId = studyId;
    this.lessonId = lessonId;
  }
}
