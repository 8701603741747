import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Element_ } from './elements/Element_';
import { SingleChoice } from './elements/QuestionSingleChoice/single-choice.component';
import { Slider } from './elements/QuestionSlider/slider.component';
import { QuestionTable } from './elements/QuestionTable/questiontable.component';
import { TextArea } from './elements/QuestionTextArea/text-area.component';
import { Date_ } from './elements/QuestionDate/text-date.component';
import { TextString } from './elements/QuestionTextString/text-string.component';
import { Space } from './elements/Space/space.component';
import { YesNo } from './elements/QuestionYesNo/yes-no.component';
import { Line } from './elements/Line/line.component';
import { MultipleChoice } from './elements/QuestionMultipleChoice/multiple-choice.component';
import { Headline } from './elements/Headline/headline.component';
import { Media } from './elements/Media/media.component';
import { Block } from './elements/Block/block.component';
import { Text_ } from './elements/Text/text.component';
import { ChatComponent } from './chat/chat.component';
import { HeaderComponent } from './header/header.component';
import { DeleteAccountComponent } from './dialogs/delete-account/delete-account.component';
import { TechnicalSupportComponent } from './dialogs/technicalsupport/technical-support.component';
import { ChatInformationComponent } from './dialogs/chat-information/chat-information.component';
import { FeedbackComponent } from './dialogs/feedback/feedback.component';
import { DirectivesModule } from '../directives/directives.module';
import { BuddyPopupComponent } from './dialogs/buddypopup/buddy-popup.component';
import { UnlockPopupComponent } from './dialogs/unlockpopup/unlock-popup.component';
import { TextProgressBar } from './progress/text-progress-bar/text-progress-bar.component';
import { Element } from './elements/Element/element.component';

@NgModule({
  entryComponents: [
    ChatComponent,
    ChatInformationComponent,
    FeedbackComponent,
    TechnicalSupportComponent,
    DeleteAccountComponent,
    Block,
    Headline,
    Line,
    Media,
    Date_,
    MultipleChoice,
    SingleChoice,
    Slider,
    QuestionTable,
    TextArea,
    TextString,
    YesNo,
    Space,
    Text_,
    Element,
    TextProgressBar,
    HeaderComponent,
    BuddyPopupComponent,
    UnlockPopupComponent,
  ],
  imports: [
    IonicModule,
    CommonModule,
    DirectivesModule,
    TranslateModule,
    FormsModule,
    Ng2FlatpickrModule,
    FontAwesomeModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatExpansionModule,
  ],
  declarations: [
    ChatComponent,
    ChatInformationComponent,
    FeedbackComponent,
    TechnicalSupportComponent,
    DeleteAccountComponent,
    Block,
    Headline,
    Line,
    Media,
    Date_,
    MultipleChoice,
    SingleChoice,
    Slider,
    QuestionTable,
    TextArea,
    TextString,
    YesNo,
    Space,
    Text_,
    Element,
    TextProgressBar,
    HeaderComponent,
    BuddyPopupComponent,
    UnlockPopupComponent,
  ],
  exports: [
    ChatComponent,
    Block,
    Headline,
    Line,
    Media,
    Date_,
    MultipleChoice,
    SingleChoice,
    Slider,
    QuestionTable,
    TextArea,
    TextString,
    YesNo,
    Space,
    Text_,
    Element,
    TextProgressBar,
    HeaderComponent,
    TranslateModule,
    FontAwesomeModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatExpansionModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule {}
