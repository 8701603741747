import {ElementModel} from "./ElementModel";

export class PageModel extends ElementModel {
  color: string;
  condition: string;
  progressbar_text: string;
  elements: Array<ElementModel> = [];
  pageIndex: number;

  constructor(position: number, color: string, condition: string, progessbar_text: string) {
    super(position);
    this.color = color;
    this.condition = condition;
    this.progressbar_text = progessbar_text;
  }
}
