import {Component, OnInit, Input} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import German from 'flatpickr/dist/l10n/de.js';
import {Question} from "../Question";
import {QuestionDateModel} from "../../../models/elements/QuestionDateModel";

@Component({
  selector: 'app-text-date',
  templateUrl: './text-date.component.html',
  styleUrls: ['./text-date.component.scss'],
})
export class Date_ extends Question implements OnInit {
  @Input() public loopIndex: number;
  model: QuestionDateModel;
  initDate: any;
  dateOptions;
  value: any = null;

  constructor(
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    let locale = German.en
    if (this.translateService.instant("LESSON.DATE_FORMAT_LAN") == "de") {
      locale = German.de
    }
    let onChange = (selectedDates: any) => {
      this.emitAnswer(selectedDates[0]);
    }
    let dateFormat = this.translateService.instant("LESSON.DATE_FORMAT_DATETIME");
    if (this.model.type == 'date') {
      dateFormat = this.translateService.instant("LESSON.DATE_FORMAT_DATE");
    } else if (this.model.type == 'time') {
      dateFormat = this.translateService.instant("LESSON.DATE_FORMAT_TIME");
    }
    let show24hrs = (this.translateService.instant("LESSON.DATE_AMPM") === "false");

    this.dateOptions = {
      altFormat: dateFormat,
      dateFormat: dateFormat,
      enableTime: !(this.model.type == 'date'),
      time_24hr: show24hrs,
      noCalendar: (this.model.type == 'time'),
      defaultHour: 0,
      defaultMinute: 0,
      locale: locale,
      clickOpens: !this.model.isDisabled,
      onClose: onChange,
      position: 'auto center'
    }

    let answer = this.currentValue;
    if(answer){
      //the answer is saved in utc, but it is what the user actually selected
      //2023-01-30T00:00:00.000Z -> so we just cut the string to 2023-01-30T00:00 
      answer = (this.model.type == 'time') ? answer.substr(10,6) : new Date(answer.substr(0,16));
      this.initDate = answer;
    }
    //emit null (so that there is an entry for each repetition) or saved answer
    this.emitAnswer(answer);
  }

  ngOnDestroy(){
    console.log("destroy pos " + this.model.position + " loop" +  this.loopIndex)
    this.model.lesson.removeAnswerRepetition(this.model.position, this.loopIndex);
  }

  public emitAnswer(value: any) {
    if (value === undefined || this.model.lesson.loading) {
      return
    }
    //selected value is date in timezone of user -> has to be in utc
    //example: user selects "30.1.23 0 uhr"
    //value of the picker is "Mon Jan 30 2023 00:00:00 GMT+0100" -> the correct time but shown in timezone of the user
    //we want exacly this time "30.1.23 0 uhr" but saved in utc, so it is the same no matter where it was saved
    //saved answer is: 2023-01-30T00:00:00.000Z
    //for init see comment above
    let answ = null;
    if(value){
      answ = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate(), value.getHours(), value.getMinutes())).toISOString();
    }

    // insert technical answer in global array
    this.model.lesson.insertAnswer(this.model.position, this.loopIndex, answ);

    let inserted = this.currentValue;
    if(inserted){
      this.initDate = new Date(inserted.substr(0,16))
    }
  }

  get currentValue(): any {
    return this.model.lesson.getAnswer(this.model.position, this.loopIndex)
  }
}
