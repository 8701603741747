import {NgModule} from "@angular/core";
import {IonicModule} from "@ionic/angular";
import {CommonModule} from "@angular/common";
import {ScrollbarThemeDirective} from "./scrollbar-theme.directive";

@NgModule({
  imports: [
    IonicModule,
    CommonModule
  ],
  declarations: [
    ScrollbarThemeDirective
  ],
  exports: [
    ScrollbarThemeDirective
  ]
})

export class DirectivesModule{}
