import {Component, EventEmitter, Output} from '@angular/core';
import {MenuController, ModalController, Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {NavigationEnd, RouterEvent} from '@angular/router';
import {TechnicalSupportComponent} from './components/dialogs/technicalsupport/technical-support.component';
import {AlertService} from './providers/alert/alert.service';
import {ParserService} from './providers/parser/parser.service';
import {LocalStorageService} from "./providers/local-storage/local-storage.service";

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
  navigate: any;
  previousUrl: string;
  smallScreen: boolean = false;
  @Output('routeEmitter') routeEmitter: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private menuCtrl: MenuController,
    private alertService: AlertService,
    private translateService: TranslateService,
    private modalController: ModalController,
    private parserService: ParserService,
    private localStorage: LocalStorageService
  ) {
    this.sideMenu();
    this.initializeApp();
    this.registerNoMenuPages();
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = event.url;
      });
  }

  initializeApp() {
    document.body.classList.toggle('darkTheme', true);
    this.translateService.setDefaultLang(this.localStorage.getAppLanguage());

    this.platform.ready().then(() => {
      if (this.platform.is('android') || this.platform.is('ios')) {
        this.statusBar.styleDefault();
        this.splashScreen.hide();
      }

      if(this.platform.width() < 992){
        this.smallScreen = true;
      }

      this.platform.resize.subscribe(() => {
        if(this.platform.width() < 992){
          this.smallScreen = true;
        }else{
          this.smallScreen = false;
        }
      });
    });
  }

  sideMenu() {
    this.navigate =
      [
        {
          title: 'PAGES.HOME',
          url: '/home',
          icon: 'home-outline',
          id: 'navHome' //dont change ids!! used for testing
        },
        {
          title: 'PAGES.MY_INTERVENTIONS',
          url: '/intervention-overview',
          icon: 'clipboard-outline',
          id: 'navInterventions'
        },
        {
          title: 'PAGES.MY_DIARIES',
          url: '/diary-overview',
          icon: 'book-outline',
          id: 'navDiaries'
        },
        {
          title: 'PAGES.CHAT',
          url: '/threads/chat-overview',
          icon: 'people-outline',
          id: 'navChat'
        },
        // {
        //   title: 'PAGES.OPEN_INTERVENTIONS',
        //   url: '/open-interventions',
        //   icon: 'globe'
        // },
        {
          title: 'PAGES.INVITATIONS',
          url: '/invitations',
          icon: 'paper-plane-outline',
          id: 'navInvitations'
        },
        {
          title: 'PAGES.MY_PROGRESS',
          url: '/progress',
          icon: 'podium-outline',
          id: 'navProgress'
        },
        {
          title: 'PAGES.MY_PROFILE',
          url: '/settings',
          icon: 'person-outline',
          id: 'navProfile'
        }
      ];
  }

  registerNoMenuPages() {
    this.router.events.subscribe((event: RouterEvent) => {
      const noMenu = ['/login', '/forgot-password', '/', '/app-imprint', '/app-privacy'];
      if (this.localStorage.getIsNativeApp() || event instanceof NavigationEnd && noMenu.includes(event.urlAfterRedirects)) {
        this.menuCtrl.enable(false);
      } else if (event instanceof NavigationEnd && !noMenu.includes(event.urlAfterRedirects)) {
        this.menuCtrl.enable(true);
      }
    });
  }

  routeToUrl(url: string, event) {
    event.stopPropagation();
    if (this.previousUrl.includes('/lesson/')) {
      this.routeEmitter.emit(url);
    } else {
      this.router.navigateByUrl(url);
    }
    this.menuCtrl.close();
  }

  closeMenu() {
    this.menuCtrl.close();
  }

  showPage(url){
    if(url === "/diary-overview"){
      return this.parserService.showDiaries
    }else if(url === "/progress"){
      return this.parserService.showProgress
    }else{
      return true
    }
  }

  async openEmailSupport() {
    const modal = await this.modalController.create({
      component: TechnicalSupportComponent,
      cssClass: 'techModal'
    });
    return await modal.present();
  }
}
