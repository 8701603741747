<div class="ion-text-left question">
  <ion-label [class.answerRequired]="model.highlight && currentValue == null" class="ion-text-wrap">
    {{model.questionResolved}}
  </ion-label>
  <div>
    <ion-button [class.selectedButton]="currentValue == true" [disabled]="model.isDisabled"
        [attr.id]="'yesButton' + model.position + '-' + loopIndex" (click)="emitAnswer(true)">
      {{yesTranslated}}
    </ion-button>
    <ion-button [class.selectedButton]="currentValue == false" [disabled]="model.isDisabled"
        [attr.id]="'noButton' + model.position + '-' + loopIndex" (click)="emitAnswer(false)">
      {{noTranslated}}
    </ion-button>
  </div>
</div>
