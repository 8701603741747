import { Input, Directive } from "@angular/core";
import {ElementModel} from "../../models/elements/ElementModel";

@Directive()
export abstract class Element_ {
  model: ElementModel;

  protected constructor() {}

  @Input() set init(element: ElementModel) {
    this.model = element;
  }
}
