// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
};

export const firebaseConfig = {
  apiKey: 'AIzaSyA-UTzMnaiVVfc9M6wJJZgC1REHc3z-yz4',
  authDomain: 'aas2-b8c15.firebaseapp.com',
  databaseURL: 'https://aas2-b8c15.firebaseio.com',
  projectId: 'aas2-b8c15',
  storageBucket: 'aas2-b8c15.appspot.com',
  messagingSenderId: '403595521371',
  appId: '1:403595521371:web:925745d4f935d1b4'
};

export const BACKEND_URL = 'https://intervention.api.aas2.klips.ifp.uni-ulm.de';
// export const BACKEND_URL = 'https://api.esano-trainings.de';
// export const BACKEND_URL = 'https://api.aas2.klips.ifp.uni-ulm.de';
export const BACKEND_URL_MEDIA = BACKEND_URL + "/uploads/studies";
export const SUPPORT_MAIL = 'support@esano-trainings.de';
export const FEATURES = [];

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
