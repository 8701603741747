import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { Thread } from "../../models/Thread";
import { BACKEND_URL, BACKEND_URL_MEDIA } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  private previousUrl: string;
  private currentUrl: string;

  constructor(
    private translateService: TranslateService,
    private router: Router,
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });
  }

  public formatDate(unformattedDate: number, locale: string): string {
    const date = new Date(unformattedDate);
    const dateOptions = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' } as const;
    const timeOptions = { hour: '2-digit', minute: '2-digit' } as const;
    let dateString = date.toLocaleDateString(locale, dateOptions) + ", " + date.toLocaleTimeString(locale, timeOptions);
    if (locale == "de-DE") {
      dateString += " Uhr";
    }
    return dateString
  }

  public getPicture(picture: string, studyId) {
    if (picture) {
      return BACKEND_URL_MEDIA + "/" + studyId + "/" + picture
    } else {
      return "../../../assets/imgs/NoPic.png"
    }
  }

  public getUserPicture(userId: number) {
    return BACKEND_URL + "/uploads/users/" + userId
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  public getStateText(instanceState: string, details: boolean) {
    if (instanceState === "awaiting_next_questionnaire") {
      return this.translateService.instant('INSTANCE.WAITING_LESSON_UNLOCK');
    } else if (instanceState === "feedback_required") {
      if (details) {
        return this.translateService.instant('INSTANCE.INSTANCE_FEEDBACK_REQUIRED');
      } else {
        return this.translateService.instant('INSTANCE.WAITING_LESSON_FEEDBACK');
      }
    } else if (instanceState === "feedback_provided") {
      return this.translateService.instant('INSTANCE.LOOK_AT_FEEDBACK');
    } else if (instanceState === "paused") {
      return this.translateService.instant('INSTANCE.INSTANCE_PAUSED');
    } else if (instanceState === "canceled") {
      return this.translateService.instant('INSTANCE.INSTANCE_CANCELED');
    } else if (instanceState === "completed") {
      return this.translateService.instant('INSTANCE.INSTANCE_COMPLETED');
    } else {
      return ""
    }
  }

  public sortThreadsByNewestDate(threads: Thread[]) {
    for (const thread of threads) {
      if (thread.messages.length !== 0) {
        threads.sort((n1, n2) => {
          if (n1.messages.length !== 0 && n2.messages.length !== 0) {
            if (n1.messages[n1.messages.length - 1].createdAt < n2.messages[n2.messages.length - 1].createdAt) {
              return 1;
            }
            if (n1.messages[n1.messages.length - 1].createdAt > n2.messages[n2.messages.length - 1].createdAt) {
              return -1;
            }
            return 0;
          } else {
            return -1;
          }
        });
      }
    }
    return threads
  }

  public encrypt(data: string, key) {
    return CryptoJS.AES.encrypt(data, key).toString();
  }

  public decrypt(data, key): string {
    let bytes = CryptoJS.AES.decrypt(data, key);
    return bytes.toString(CryptoJS.enc.Utf8);
  }

  // Expects format: '#rrggbb'
  brightenHexColor(color: string, percent: number) {
    color = color.substr(1, 6);
    var r = parseInt(color.substr(0, 2), 16);
    var g = parseInt(color.substr(2, 2), 16);
    var b = parseInt(color.substr(4, 2), 16);

    return '#' +
      ((0 | (1 << 8) + r + (256 - r) * percent / 100).toString(16)).substr(1) +
      ((0 | (1 << 8) + g + (256 - g) * percent / 100).toString(16)).substr(1) +
      ((0 | (1 << 8) + b + (256 - b) * percent / 100).toString(16)).substr(1);
  }
}
