<ion-header>
  <ion-toolbar class="header">
    <ion-title class="whiteFont">
      {{"CHAT_FEEDBACK.FEEDBACK" | translate}}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button id="closeButton" (click)="dismiss()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="secondary">
      <ion-button id="showChatInfoButton" (click)="presentChatInformation()">
        <ion-icon slot="icon-only" name="people"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-fab vertical="top" horizontal="end" slot="fixed">
  <ion-fab-button size="small" id="scrollDownButton" (click)="scrollBottomSlow()">
    <ion-icon name="arrow-down"></ion-icon>
  </ion-fab-button>
</ion-fab>
<ion-content #chatContentRef appScrollbarTheme class="modalBackground">
  <ion-grid *ngIf="thread">
    <ion-row *ngFor="let message of thread.messages; let i = index" [attr.id]="'message' + i">
      <ion-col class="message" [class.my-message]="message.authorId == myUserId" [class.other-message]="message.authorId != myUserId"
          size="9" offset="{{getOffsetSize(message)}}">
        <b>
          {{ message.authorName }}
        </b>
        <br>
        <span>
          {{ message.message }}
        </span>
        <div class="time ion-text-right">
          <br>
          {{ message.createdAt | date: 'dd.MM.yyyy, HH:mm' }}
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer>
  <ion-toolbar color="light">
    <ion-row class="ion-no-padding ion-align-items-center">
      <ion-col size="10">
        <textarea [(ngModel)]="newMsg" id="inputMessageChat" autosize class="message-input" maxRows="4"></textarea>
      </ion-col>
      <ion-col size="2">
        <ion-button expand="block" fill="clear" color="primary" [disabled]="newMsg === ''" class="msg-btn" id="sendMessageButton" (click)="sendMessage()">
          <ion-icon name="send" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-toolbar>
</ion-footer>
