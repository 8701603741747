<div class="dialog">
  <ion-buttons style="justify-content: flex-end; margin-bottom: 20px">
    <ion-button id="closeButton" (click)="onCloseDialog()">
      <ion-icon name="close-circle-outline" size="large"></ion-icon>
    </ion-button>
  </ion-buttons>
  <div class="information">
    <div *ngIf="offers.length > 0 && unlockIds.length > 0">
      <div *ngIf="unlockIsDifferent()" style="color: red">
        FEHLER: Bitte Screenshot machen & url notieren
        debug freischaltbar:<br>
        server: {{unlockServer}}<br>
        app: {{unlockIds}}
      </div>
    </div>

    <div *ngIf="hasAlreadyUnlockedOffers.length > 0">
      <div *ngIf="alreadyUnlockedIsDifferent()" style="color: red">
        FEHLER: Bitte Screenshot machen & url notieren
        debug schon freigeschaltet:<br>
        {{alreadyUnlockedServer}}<br>
        {{hasAlreadyUnlockedOffers}}
      </div>
    </div>

    <h3>
      {{getText()}}
    </h3>

    <div *ngIf="offers.length == 0" class="information">
      <ul *ngFor="let entry of alreadyUnlockedDetails" class="alreadyUnlockedLessons">
        <li>
          {{entry}}
        </li>
      </ul>

      <p>
        {{"LESSON.ALREADY_UNLOCKED_BONUSLESSONS_INFO" | translate}}
      </p>
    </div>

    <h4 *ngIf="offers.length > 0">
      {{getUnlockOneMultipleText()}}
    </h4>

  </div>
  <div *ngIf="offers.length > 0" id="unlockQuestionnairesList" class="questionnaires" [class.topBorder]="showTopBorder && !showBottomBorder"
      [class.bottomBorder]="showBottomBorder && !showTopBorder" [class.bothBorders]="showTopBorder && showBottomBorder">
    <ion-grid>
      <ion-radio-group *ngIf="!unlockMultiple" [(ngModel)]="selectedLesson">
        <div *ngFor="let offer of offers; let i = index">
          <ion-row class="ion-align-items-center" style="text-align-last: end">
            <ion-col size="1" style="text-align-last: end">
              <ion-radio [value]="i" [attr.id]="'inputSelectLesson' + i"></ion-radio>
            </ion-col>
            <ion-col size="11">
              <ion-card [class.choosen]="selectedLesson === i" [attr.id]="'lessonCard' + i" (click)="selectedLesson = (selectedLesson === i) ? -1 : i">
                <ng-container>
                  <ion-grid>
                    <ion-row>
                      <ion-card-header>
                        <ion-card-title>
                          {{offer.unlock.title}}
                        </ion-card-title>
                      </ion-card-header>
                    </ion-row>
                    <ion-row *ngIf="offer.unlock.description">
                      <ion-card-content>
                        {{offer.unlock.description}}
                      </ion-card-content>
                    </ion-row>
                  </ion-grid>
                </ng-container>
              </ion-card>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="!unlockMultiple && offers.length > 0 && selectedLesson != -1 && selectedLesson != undefined && selectedLesson === i" class="ion-align-items-center resetButton" style="text-align-last: end">
            <ion-button id="removeSelectionButton" color="primary" fill="solid" (click)="removeSelection()">
              {{ 'LESSON.RESET' | translate }}
            </ion-button>
          </ion-row>
        </div>
        <!-- <ion-row class="ion-align-items-center" style="text-align-last: end">
          <ion-col size="1" style="text-align-last: end">
            <ion-radio [value]="-2"></ion-radio>
          </ion-col>
          <ion-col size="11">
            <ion-card [class.choosen]="selectedLesson === -2" (click)="selectedLesson = (selectedLesson === -2) ? -1 : -2">
              <ng-container>
                <ion-grid>
                  <ion-row>
                    <ion-card-header>
                      <ion-card-title>
                        {{ 'LESSON.NONE' | translate }}
                      </ion-card-title>
                    </ion-card-header>
                  </ion-row>
                </ion-grid>
              </ng-container>
            </ion-card>
          </ion-col>
        </ion-row> -->
      </ion-radio-group>
      <div *ngIf="unlockMultiple">
        <ion-row *ngFor="let offer of offers; let i = index" class="ion-align-items-center" style="text-align-last: end">
          <ion-col size="1" style="text-align-last: end">
            <ion-checkbox [(ngModel)]="choosenOffers[i]" [attr.id]="'inputSelectLesson' + i"></ion-checkbox>
          </ion-col>
          <ion-col size="11">
            <ion-card [class.choosen]="choosenOffers[i]" [attr.id]="'lessonCard' + i" (click)="choosenOffers[i] = !choosenOffers[i]">
              <ng-container>
                <ion-grid>
                  <ion-row>
                    <ion-card-header>
                      <ion-card-title>
                        {{offer.unlock.title}}
                      </ion-card-title>
                    </ion-card-header>
                  </ion-row>
                  <ion-row *ngIf="offer.unlock.description">
                    <ion-card-content>
                      {{offer.unlock.description}}
                    </ion-card-content>
                  </ion-row>
                </ion-grid>
              </ng-container>
            </ion-card>
          </ion-col>
        </ion-row>
      </div>
    </ion-grid>
  </div>
  <div id="scrollUnlockLessons" class="scrollIndication">
    <fa-icon [icon]="faAngleDoubleDown"></fa-icon>
  </div>
  <div *ngIf="hasAlreadyUnlockedOffers.length > 0 && offers.length > 0" class="information">
    <h5>
      {{"LESSON.ALREADY_UNLOCKED_BONUSLESSONS" | translate}}
    </h5>

    <ul *ngFor="let entry of alreadyUnlockedDetails" class="alreadyUnlockedLessons">
      <li>
        {{entry}}
      </li>
    </ul>

    <p>
      {{"LESSON.ALREADY_UNLOCKED_BONUSLESSONS_INFO" | translate}}
    </p>
  </div>
  <div class="dialog-action">
    <ion-button id="unlockLessonsButton" class="dialog-action" color="primary" fill="solid" (click)="unlock()">
      {{getButtonText()}}
    </ion-button>
  </div>
</div>
