import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";
import {faAngleDoubleDown} from '@fortawesome/free-solid-svg-icons';
import {AlertService} from "../../../providers/alert/alert.service";
import {Lesson} from "../../../models/Lesson";
import {Instance} from "../../../models/Instance";

@Component({
  selector: 'app-unlock-popup',
  templateUrl: './unlock-popup.component.html',
  styleUrls: ['./unlock-popup.component.scss'],
})
export class UnlockPopupComponent implements OnInit {
  // after submit answersheet: unlock dialog is shown if
  // - condition fulfilled, lesson not yet unlocked -> lesson shown
  // - condition fulfilled, lesson unlocked, lesson is bonus lesson -> text shown
  // if lessons for both cases -> lessons & text shown

  //lesson overview:
  //for finished lessons, which can still unlock lessons, the unlock button is shown -> this dialog can be opened again
  //which can still unlock lessons meaning:
  //not all lessons which can be unlocked by this lesson are already unlocked
  //if (unlock multiple === false) additionally: none was unlocked yet

  //diary page:
  //one unlock button (for the last submitted answersheet) -> this dialog can be opened again
  //shown only if not all lessons which can be unlocked by this diary are already unlocked
  //if button is once hidden, it stays hidden; if it is still shown there are some lessons which could be unlocked (but maybe not for current answersheet)
  //
  //button is shown in this cases, unlock dialog will not be opened, but alert will be shown
  //case 1: answers of last answersheet do not fulfill condition (clicking shows 'gerade keine passenden lektionen')
  //case 2: if unlock multiple false & one was already unlocked for this answersheet (clicking shows 'bereits 1 Zusatzlektion freigeschaltet, nächste nach nächstem Eintrag freigeschaltbar')

  @Input() offers: Array <{instance: Instance, unlock: Lesson}>;
  @Input() unlockMultiple: Boolean;
  @Input() unlockServer: Array <number>;
  @Input() unlockIds: Array <number>;
  @Input() isDiary: Boolean;
  @Input() hasAlreadyUnlockedOffers: Array <number>;
  @Input() alreadyUnlockedServer: Array <number>;
  @Input() alreadyUnlockedDetails: Array <string>;

  public faAngleDoubleDown = faAngleDoubleDown;
  choosenOffers = [];
  selectedLesson = -1;
  showTopBorder: boolean = false;
  showBottomBorder: boolean = true;
  userScrolled: boolean = false;

  constructor(
    private modalController: ModalController,
    private alertController: AlertService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.choosenOffers = new Array(this.offers.length).fill(false);

  }

  ngAfterViewInit() {
    let self = this;
    document.getElementById('unlockQuestionnairesList').addEventListener("scroll", event => {
      const e = (event.target as HTMLInputElement);

      if(e.scrollTop < 10){
        //0-9 -> only show bottom
        self.showTopBorder = false;
        self.showBottomBorder = true;
      }else if(e.scrollTop + e.clientHeight < e.scrollHeight - 10){
        // 10 - (height - 9) -> show both
        self.showTopBorder = true;
        self.showBottomBorder = true;
      }else{
        //(height - 9) - height -> only show top
        self.showTopBorder = true;
        self.showBottomBorder = false;
      }

      if(e.scrollTop > 100){
        self.userScrolled = true;
      }

      if(window.screen.width < 700){
        document.getElementById("scrollUnlockLessons").style.display = (!self.userScrolled && document.getElementById("scrollUnlockLessons").style.display != "none") ? "block" : "none";
      }
    });
  }

  removeSelection(i: number) {
    this.selectedLesson = -1;
  }

  getChoosenQuestionnaires(): Array<number>{
    let result = [];
    for (let i = 0; i < this.offers.length; i++){
      if (this.choosenOffers[i]){
        result.push(this.offers[i])
      }
    }
    return result;
  }

  unlock() {
    if((this.unlockMultiple && !this.choosenOffers.includes(true)) || !this.unlockMultiple && (this.selectedLesson === -1 || this.selectedLesson === -2 || this.selectedLesson === undefined)){
      // no choosen questionnaires
      this.onCloseDialog();
    }else{
      let result = this.unlockMultiple ? this.getChoosenQuestionnaires() : this.offers[this.selectedLesson];
      this.modalController.dismiss(result);
    }
  }

  onCloseDialog() {
    if(this.offers.length > 0){
      this.alertController.showChoice('LESSON.NO_UNLOCK', 'LESSON.SURE_NO_UNLOCK', 'BUTTONS.CANCEL', 'BUTTONS.YES',
        () => {
          // brings user back to selection
        },
        () => {
          // will return no content to unlock
          this.modalController.dismiss();
        });
    }else{ //unlock modal only opened with offers length === 0 for diaries
      //just close modal
      this.modalController.dismiss();
    }
  }

  getText(){
    if(this.offers.length > 0){
      return this.translateService.instant('LESSON.UNLOCK_INFO')
    }else{ //unlock modal only opened with offers length === 0 for bonus lessons
      return this.translateService.instant('LESSON.ALL_UNLOCKED')
    }
  }

  getButtonText(){
    if(this.offers.length > 0){
      return this.translateService.instant('BUTTONS.UNLOCK')
    }else{ //unlock modal only opened with offers length === 0 for bonus lessons
      return this.translateService.instant('BUTTONS.OK')
    }
  }

  getUnlockOneMultipleText(){
    return this.unlockMultiple ? this.translateService.instant('LESSON.UNLOCK_INFO_MULTIPLE') : this.translateService.instant('LESSON.UNLOCK_INFO_ONE')
  }

  unlockIsDifferent(){
    return JSON.stringify(this.unlockServer) != JSON.stringify(this.unlockIds)
  }

  alreadyUnlockedIsDifferent(){
    return JSON.stringify(this.hasAlreadyUnlockedOffers) != JSON.stringify(this.alreadyUnlockedServer)
  }
}
