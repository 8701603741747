import { Component } from '@angular/core';
import { Element_ } from "../Element_";

@Component({
  selector: 'app-line',
  templateUrl: './line.component.html',
  styleUrls: ['./line.component.scss'],
})
export class Line extends Element_ {
  constructor() {
    super();
  }
}
