import {MediaType} from '../../../app/models/elements/Enums';
import {ElementModel} from "./ElementModel";

export class MediaModel extends ElementModel {
  resolvable = ['subtitle', 'description'];
  subtitle: string;
  subtitleResolved: string;
  description: string;
  descriptionResolved: string;
  type: MediaType;
  webUri;
  isAudio: boolean;
  localUri;
  filePath: string;
  fileName: string;
  width: string;
  height: string;
  banner: boolean;
  platformIsAndroid: boolean = false;

  constructor(position: number, type: MediaType, isAudio: boolean, webUri, localUri, description: string, subtitle: string, filePath: string,
    fileName: string, width: string, height: string, banner: boolean, platformIsAndroid: boolean) {
    super(position);
    this.type = type;
    this.webUri = webUri;
    this.isAudio = isAudio;
    this.localUri = localUri;
    this.description = description;
    this.subtitle = subtitle;
    this.filePath = filePath;
    this.fileName = fileName;
    this.width = width;
    this.height = height;
    this.banner = banner;
    this.platformIsAndroid = platformIsAndroid;
  }
}
