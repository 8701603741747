<div [class.required]="model.required" [class.wideTable]="numberOfValues > 3" class="ion-text-left question questionTable">
  <div *ngFor="let question of questionTexts; let i = index">
    <ion-label [class.answerRequired]="model.highlight && currentValue[i] == null" class="ion-text-wrap ionLabelText" color="page-font">
      {{question}}
    </ion-label>
    <ion-radio-group [(ngModel)]="value[i]" (ionChange)="emitAnswer()">
      <ion-list>
        <div>
          <ion-item *ngFor="let n of [].constructor(numberOfValues); let m = index" class="ion-text-wrap" lines="none">
            <ion-label class="ion-text-wrap">
              {{model.answerTexts[model.minVal + m]}}
            </ion-label>
            <ion-radio slot="start" [value]="model.minVal + m" [disabled]="model.lesson.answersheet"
              [attr.id]="'questionTable' + model.position + '-' + loopIndex + '-q' + i + '-a' + m"></ion-radio>
          </ion-item>
        </div>
      </ion-list>
    </ion-radio-group>
  </div>
</div>
