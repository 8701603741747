export class Announcement{
  id: number;
  type: string;
  title: string;
  message: string;

  constructor(id: number, type: string, title: string, message: string) {
    this.id = id;
    this.type = type;
    this.title = title;
    this.message = message;
  }
}
