<ion-header>
  <ion-toolbar>
    <ion-title>
      {{"CHAT_FEEDBACK.CHAT_INFORMATION" | translate}}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button id="closeButton" (click)="dismiss()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <h2 class="ion-text-center">
    {{"CHAT_FEEDBACK.PARTICIPANTS" | translate}}
  </h2>
  <ion-list>
    <ion-item *ngFor="let user of users">
      <ion-avatar slot="start">
        <img [src]=helperService.getUserPicture(user.id)>
      </ion-avatar>
      <ion-label>
        {{user.name}}
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>
