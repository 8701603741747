export class Invitation {
  id: number;
  expiresAt: number;
  invitationToken: string;
  studyTitle: string;

  constructor(id: number, expiresAt: number, invitationToken: string, studyTitle: string) {
    this.id = id;
    this.expiresAt = expiresAt;
    this.invitationToken = invitationToken;
    this.studyTitle = studyTitle;
  }
}
