<div *ngIf="hasDetails()" class="ion-text-left question" [hidden]="hideDetails">
  <details [attr.id]="'details' + model.position + '-' + loopIndex" (toggle)="toggleBlock()">
    <summary [attr.id]="'summary' + model.position + '-' + loopIndex" [hidden]="!hasDetails()">
      <div>
        {{model.text}}
      </div>
    </summary>
    <span *ngFor="let i of numberOfRepetitions" [attr.id]="'content' + model.position + '-' + loopIndex"> <!-- this is the same in both cases but works better if inside details; todo use dynamic tag / one for both -->
      <ng-container *ngFor="let element of model.elements">
        <app-element [init]="element" [loopIndex]="loopIndex * model.repeat.data + i - 1" [hidden]="hideElements">
        </app-element>
      </ng-container>
    </span>
  </details>
</div>

<div *ngIf="!hasDetails()">
  <span *ngFor="let i of numberOfRepetitions"> <!-- this is the same in both cases but works better if inside details; todo use dynamic tag / one for both -->
    <ng-container *ngFor="let element of model.elements">
      <app-element [init]="element" [loopIndex]="loopIndex * model.repeat.data + i - 1" [hidden]="hideElements">
      </app-element>
    </ng-container>
  </span>
</div>
