import { Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import { HelperService } from 'src/app/providers/helper/helper.service';

@Component({
  selector: 'app-text-progress-bar',
  templateUrl: './text-progress-bar.component.html',
  styleUrls: ['./text-progress-bar.component.scss']
})
export class TextProgressBar implements OnInit {
  @Input() pxProgressBarHeight: number;
  @Input() pxFontSize: number;
  @Input() value: number;
  @Input() text: string;
  @Input() progressBackground: string = null;
  @ViewChild('progressBarRef', { read: ElementRef }) progressBarRef: ElementRef;

  constructor(
    private helperService: HelperService
  ) { }

  ngOnInit(){
    // if no font size specified, use progress bar height
    if(this.text && !this.pxFontSize){
      this.pxFontSize = this.pxProgressBarHeight;
    }
  }

  ngAfterViewInit() {
    if(this.progressBackground){
      let pbStyle = this.progressBarRef.nativeElement.style;
      pbStyle.setProperty("--progress-background", this.progressBackground);
      //use a brightened version of progressBackground
      pbStyle.setProperty("--background", this.helperService.brightenHexColor(this.progressBackground, 50));
    }
  }
}
