<ion-buttons style="justify-content: flex-end;">
  <ion-button id="closeButton" (click)="onCloseDialog()">
    <ion-icon name="close-circle-outline" size="large"></ion-icon>
  </ion-button>
</ion-buttons>

<div class="dialog-content">
  <h3>
    {{ 'SUPPORT.TECHNICAL_SUPPORT' | translate }}
  </h3>
  <p>
    {{ 'SUPPORT.INFO_TEXT' | translate }}
  </p>
  <p style="text-align: center">
    {{supportMail}}
  </p>
</div>

<ion-buttons class="dialog-actions">
  <ion-button class="dialog-action" color="primary" fill="solid" id="sendMailButton" (click)="onOpenEmailClient()">
    {{ 'SUPPORT.SEND_MAIL' | translate }}
  </ion-button>
</ion-buttons>
