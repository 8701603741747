export class Intervention {
  id: number;
  title: string;
  description: string;
  type: string;
  gamification: Gamification;
  unlockMultiple: boolean;
  smallLayout: boolean;

  constructor(id: number, title: string, description: string, type: string, gamification: Gamification, unlockMultiple, smallLayout: boolean){
      this.id = id;
      this.title = title;
      this.description = description;
      this.type = type;
      this.gamification = gamification;
      this.unlockMultiple = unlockMultiple;
      this.smallLayout = smallLayout;
  }
}

export interface Gamification{
    progressbars_enabled: boolean;
    skills_order: Array<number>;
}
