import {SafeHtml} from "@angular/platform-browser";
import {ElementModel} from "./ElementModel";
import {TextType} from "../../../app/models/elements/Enums";

export class TextModel extends ElementModel {
  resolvable = ['text', 'headline'];
  text: string;
  _textResolved: SafeHtml;
  type: TextType;

  constructor(positon: number, text: string, type: TextType) {
    super(positon);
    this.text = text;
    this.type = type;
  }

  public set textResolved(value: string) {
    this._textResolved = this.lesson.sanitizer.bypassSecurityTrustHtml(value);
  }
}
