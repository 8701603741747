import {Answersheet} from "./Answersheet";

export class DiaryInstance{
  id: number;
  diaryID: number;
  title: string;
  description: string;
  picture: string;
  lessonOfDiary: number;
  studyId: number;
  answersheets: Answersheet[] = [];
  interventionInstanceId: number;
  hasQuicksavedAnswers: boolean = false;

  constructor(id: number, diaryID: number, title: string, description: string, picture: string, lessonOfDiary: number, studyId: number, interventionInstanceId: number) {
    this.id = id;
    this.diaryID = diaryID;
    this.title = title;
    this.description = description;
    this.picture = picture;
    this.lessonOfDiary = lessonOfDiary;
    this.studyId = studyId;
    this.interventionInstanceId = interventionInstanceId;
  }
}
