import {NgModule, Directive, ElementRef, Input, OnInit} from '@angular/core';

@Directive({
    selector: '[appScrollbarTheme]'
})
export class ScrollbarThemeDirective implements OnInit{
  @Input('hide') hide = false;

  saveRef: ElementRef;

  constructor(
    elRef: ElementRef
  ) {
    // can't access width parameter in constructor, so saving reference to access on ngOnInit
    this.saveRef = elRef;
  }

  ngOnInit(): void {
    let width = 10
    if (this.hide){
      width = 0;
    }
    const stylesheetBlue =
      `::-webkit-scrollbar-track
        {
        	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        	background-color: #F5F5F5;
        	border-radius: 10px;
        }
      ::-webkit-scrollbar
        {
          width: `+ width + `px;
          background-color: #000;
        }
      ::-webkit-scrollbar-thumb
        {
          border-radius: 10px;
          background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.44, rgb(122,153,217)),
          color-stop(0.72, rgb(73,125,189)), color-stop(0.86, rgb(28,58,148)));
        }`;

    const stylesheet =
      `::-webkit-scrollbar-track
        {
          -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
          border-radius: 10px;
          background-color: #F5F5F5;
        }

      ::-webkit-scrollbar
        {
          width: `+ width + `px;
          background-color: --body-color;
        }

      ::-webkit-scrollbar-thumb
        {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
          background-color: #1d3557;
        }`;

    const styleElmt = this.saveRef.nativeElement.shadowRoot.querySelector('style');
    if (styleElmt) {
      styleElmt.append(stylesheet);
    } else {
      const barStyle = document.createElement('style');
      barStyle.append(stylesheet);
      this.saveRef.nativeElement.shadowRoot.appendChild(barStyle);
    }
  }
}
