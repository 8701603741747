import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Platform } from "@ionic/angular";
import { File } from "@ionic-native/file/ngx";
import { TranslateService } from "@ngx-translate/core";
import { User } from "../../models/User";
import { Diary } from "../../models/Diary";
import { DiaryInstance } from "../../models/DiaryInstance";
import { Activity } from "../../models/Activity";
import { Invitation } from "../../models/Invitation";
import { Instance } from "../../models/Instance";
import { Lesson } from "../../models/Lesson";
import { Answersheet } from "../../models/Answersheet";
import { Thread } from "../../models/Thread";
import { Message } from "../../models/Message";
import { ElementModel } from "../../models/elements/ElementModel";
import { BlockModel } from "../../models/elements/BlockModel";
import { LineModel } from "../../models/elements/LineModel";
import { PageModel } from "../../models/elements/PageModel";
import { SpaceModel } from "../../models/elements/SpaceModel";
import { TextModel } from "../../models/elements/TextModel";
import { HeadlineModel } from "../../models/elements/HeadlineModel";
import { MediaModel } from "../../models/elements/MediaModel";
import { QuestionYesNoModel } from "../../models/elements/QuestionYesNoModel";
import { QuestionSingleChoiceModel } from "../../models/elements/QuestionSingleChoiceModel";
import { QuestionMultipleChoiceModel } from "../../models/elements/QuestionMultipleChoiceModel";
import { QuestionSliderModel } from "../../models/elements/QuestionSliderModel";
import { QuestionTableModel } from "../../models/elements/QuestionTableModel";
import { QuestionTextStringModel } from "../../models/elements/QuestionTextStringModel";
import { QuestionTextAreaModel } from "../../models/elements/QuestionTextAreaModel";
import { QuestionDateModel } from "../../models/elements/QuestionDateModel";
import { Announcement } from "../../models/Announcement";
import { AlertService } from "../alert/alert.service";
import { HelperService } from "../helper/helper.service";
import { RequestProviderService } from "../../providers/request-provider/request-provider.service";
import { LocalStorageService } from "../local-storage/local-storage.service";
import {
  BlockType,
  MediaType,
  TextType,
} from "../../../app/models/elements/Enums";
import { BACKEND_URL } from "../../../environments/environment";
import { Intervention } from "../../models/Intervention";
import { Skill } from "src/app/models/Skill";

@Injectable({
  providedIn: "root",
})
export class ParserService {
  public activityLog: Array<Activity> = [];
  public invitations: Array<Invitation> = [];
  public newMessages: number = 0;
  public newFeedback: number = 0;
  public announcements: Array<Announcement> = [];
  public showAnnouncements: boolean = false;
  public selectedAnnouncement: number = 0;
  public showDiaries: boolean = true;
  public showProgress: boolean = true;

  constructor(
    private file: File,
    private sanitizer: DomSanitizer,
    private platform: Platform,
    private alertService: AlertService,
    private localStorage: LocalStorageService,
    private requestProvider: RequestProviderService,
    private helperService: HelperService,
    private translationService: TranslateService
  ) {}

  public parseUser(userJSON: object): User {
    let user = userJSON["body"].data;
    if (!user.attributes.settings) {
      user.attributes.settings = {};
    }
    if (!user.attributes.settings.background) {
      user.attributes.settings.background = "0";
    }
    if (!user.attributes.settings.theme) {
      user.attributes.settings.theme = "0";
    }
    if (!user.attributes.settings.language) {
      user.attributes.settings.language = "de";
    }
    if (!user.attributes.settings.locale) {
      user.attributes.settings.locale = "de";
    }
    let result = new User(
      user.id,
      user.attributes.name,
      user.attributes.firstname,
      user.attributes.lastname,
      user.attributes.settings
    );
    return result;
  }

  public parseActivities(activityArrJSON: object): Array<Activity> {
    let activityArr = activityArrJSON["body"].data;
    let result = [];
    for (let index in activityArr) {
      let attributesJSON = activityArr[index].attributes;
      let activityText = attributesJSON.text;
      if (attributesJSON.name != null) {
        activityText = activityText.replace("{{name}}", attributesJSON.name);
      }
      result.push(
        new Activity(
          activityArr[index].id,
          activityText,
          attributesJSON.action,
          new Date(attributesJSON.created_at)
        )
      );
    }
    return result;
  }

  public parseInvitations(invitationArrJSON: object): Array<Invitation> {
    let invitationArr = invitationArrJSON["body"].data;
    let result = [];
    for (let index in invitationArr) {
      let timestamp = Number(
        invitationArr[index].attributes.expires_at + "000"
      );
      result.push(
        new Invitation(
          invitationArr[index].id,
          timestamp,
          invitationArr[index].attributes.uri.replace(
            BACKEND_URL + "/api/v1/studies/invitation?token=",
            ""
          ),
          invitationArr[index].attributes.study_title
        )
      );
    }
    this.invitations = result;
    return result;
  }

  public parseInterventions(interventionArrJSON: object): Array<Intervention> {
    let interventionsArr = interventionArrJSON["body"].data;
    let result = [];
    for (let index in interventionsArr) {
      result.push(this.parseIntervention(interventionsArr[index]));
    }
    return result;
  }

  public parseIntervention(interventionJSON: object): Intervention {
    let attributes = interventionJSON["attributes"];
    let id = interventionJSON["id"];
    let unlockMultiple;
    if (interventionJSON["body"]) {
      attributes = interventionJSON["body"].data.attributes;
      id = interventionJSON["body"].data.id;
      unlockMultiple =
        attributes.unlock_multiple_conditionals === undefined ||
        attributes.unlock_multiple_conditionals == 1 ||
        attributes.unlock_multiple_conditionals == true
          ? true
          : false;
    }
    return new Intervention(
      id,
      attributes.title,
      attributes.description,
      attributes.type,
      attributes.gamification,
      unlockMultiple,
      attributes.small_layout
    );
  }

  public parseInstances(
    instanceArrJSON: object,
    interventions: Array<Intervention>
  ): Array<Instance> {
    let instanceArr = instanceArrJSON["body"].data;
    let result = [];
    for (let index in instanceArr) {
      result.push(this.parseInstance(instanceArr[index]));
    }

    //check if user has instances with skills
    if (interventions.length > 0) {
      let instancesWithSkills = result.filter(
        (instance) =>
          instance.progress.current_state != "completed" &&
          instance.progress.current_state != "canceled"
      );
      instancesWithSkills = instancesWithSkills.filter((instance) => {
        let intervention = interventions.find(
          (i) => i.id == instance.intervention_id
        );
        return (
          intervention.gamification &&
          intervention.gamification.skills_order &&
          intervention.gamification.skills_order.length
        );
      });
      this.showProgress = instancesWithSkills.length > 0;
    }

    return result;
  }

  public parseInstance(instanceJSON: object): Instance {
    let attributes = instanceJSON["attributes"];
    let id = instanceJSON["id"];
    let unlockMultiple;
    if (instanceJSON["body"]) {
      attributes = instanceJSON["body"].data.attributes;
      id = instanceJSON["body"].data.id;
      unlockMultiple =
        attributes.unlock_multiple_conditionals === undefined ||
        attributes.unlock_multiple_conditionals == 1 ||
        attributes.unlock_multiple_conditionals == true
          ? true
          : false;
    }
    let result = new Instance(
      id,
      attributes.intervention_id,
      attributes.intervention_type,
      attributes.ecoach_id,
      attributes.patient_id,
      attributes.configuration,
      attributes.progress,
      new Date(attributes.created_at),
      attributes.intervention_title,
      attributes.intervention_description,
      attributes.intervention_media,
      attributes.study_id,
      unlockMultiple
    );
    return result;
  }

  public parseLessons(lessonJSON: object): Array<Lesson> {
    let lessonArr = lessonJSON["body"].data;
    let result = [];
    for (let index in lessonArr) {
      result.push(this.parseLesson(lessonArr[index]));
    }
    return result;
  }

  public parseLesson(lessonJSON: object): Lesson {
    let attributes = lessonJSON["attributes"];
    let id = lessonJSON["id"];
    if (lessonJSON["body"]) {
      attributes = lessonJSON["body"].data.attributes;
      id = lessonJSON["body"].data.id;
    }
    let defaultColor = attributes.page_color;
    if (!defaultColor || defaultColor == null || defaultColor == "") {
      defaultColor = "#FEFCF0";
    } else if (!defaultColor.startsWith("#")) {
      defaultColor = this.helperService.getPicture(
        defaultColor,
        attributes.study_id
      );
    }

    let unlock_diaries = [];
    if (attributes.unlock_diaries != null) {
      unlock_diaries = attributes.unlock_diaries;
    }
    let result = new Lesson(
      parseInt(id),
      attributes.title,
      attributes.description,
      attributes.is_filled_out,
      defaultColor,
      unlock_diaries,
      attributes.questions_required == 1,
      attributes.diary_id,
      attributes.study_id,
      attributes.skills
    );
    return result;
  }

  public parseDiaryInstances(
    diaryInstanceArrJSON: object,
    isAllInstances: boolean
  ): Array<DiaryInstance> {
    let diaryInstanceArr = diaryInstanceArrJSON["body"].data;
    let result = [];
    for (let index in diaryInstanceArr) {
      result.push(this.parseDiaryInstance(diaryInstanceArr[index]));
    }
    //showDiaries if all instances are requested or if requested for a specific intervention instance, which now has diary instances (result.length > 0)
    if (isAllInstances || result.length > 0) {
      this.showDiaries = result.length > 0;
    }
    return result;
  }

  public parseDiaryInstance(diaryInstanceJSON: object): DiaryInstance {
    let attributes = diaryInstanceJSON["attributes"];
    return new DiaryInstance(
      diaryInstanceJSON["id"],
      attributes.diary_id,
      attributes.diary_title,
      attributes.diary_description,
      attributes.diary_picture,
      attributes.diary_questionnaire_id,
      attributes.study_id,
      attributes.intervention_instance_id
    );
  }

  public parseDiary(diaryJSON: object): Diary {
    let diary = diaryJSON["body"].data;
    let result = new Diary(
      diary.id,
      diary.attributes.title,
      diary.attributes.description,
      diary.attributes.picture,
      diary.attributes.study_id,
      diary.attributes.questionnaire_id
    );
    return result;
  }

  public parseAnswersheets(answersheetArrJSON: object): Array<Answersheet> {
    let answersheetArr = answersheetArrJSON["body"].data;
    let result = [];
    for (let index in answersheetArr) {
      result.push(this.parseAnswersheet(answersheetArr[index]));
    }
    return result;
  }

  public parseAnswersheet(answersheetJSON: object): Answersheet {
    let attributes = answersheetJSON["attributes"];
    let id = answersheetJSON["id"];
    if (answersheetJSON["body"]) {
      attributes = answersheetJSON["body"].data.attributes;
      id = answersheetJSON["body"].data.id;
    }

    let result = new Answersheet(
      id,
      attributes.questionnaire_id,
      attributes.intervention_instance_id,
      attributes.diary_instance_id,
      attributes.answers,
      attributes.locale,
      <number>attributes.collected_at * 1000,
      attributes.version
    );
    return result;
  }

  /**
   * @param elementsJson (String), contains all Elements
   * @param returns (JSON Object), all question elements as json with element properties at key position;
   * needs keys
   * - questionType (String) (for TextDate questions the specific type from values array has to be used here)
   *`- values (Array) (for Single/Multiple Choice, can be empty otherwise)
   * - translations (JSON Object with language as key, Array with answer strings as value) (answer translations, for Single/Multiple Choice, can be empty otherwise)
   *
   * example: {"2": {"questionType": "TextDate", "values": [], translations: {}}, "5": {"questionType": "SingleChoice", "values": [a, b],
   * translations: {"en": ["answer a", "answer b"], "de": ["Antwort a", "Antwort b"]}}}
   */
  parseLabelList(elementJSON: object): any {
    let parsedResponse = elementJSON["body"].data.attributes.elements;
    if (parsedResponse.length == 0) {
      return [];
    }

    let language = this.localStorage.getAppLanguage();
    let result = {};
    for (let entry of parsedResponse) {
      if (entry.elementtype != "elements/questions") continue;
      let type = entry["questiontype"];

      if (type == "TextDate") {
        type =
          "Text" +
          entry.values[0].replace("date", "Date").replace("time", "Time");
      }

      result[entry.position] = {
        questionType: type,
        values: entry.values,
        translations: {
          [language]: entry.answers,
        },
      };
    }
    return result;
  }

  /**
   * @method parseElements
   * @param elementJSON
   * @return array of PageComponents. Each PageComponent has an array containing ElementModels (e.g. QuestionModels and their QuestionType)
   *
   * todoNewElementFormat maybe check for questions, conditional blocks etc in skills
   */
  public parseElements(elementJSON: object): Array<PageModel> {
    let parsedResponse = elementJSON["body"].data.attributes.elements;
    if (parsedResponse.length == 0) {
      return [new PageModel(0, "", "", "")];
    }

    // iterate through all elements of the response and try to parse them
    let elements: Array<PageModel | BlockModel> = [];
    // the first element always has to be a page
    // old lessons don't have this
    if (parsedResponse[0].elementtype != "elements/pages") {
      elements.push(new PageModel(0, "", "", ""));
    }

    for (let entry of parsedResponse) {
      let parsedElement = null;
      switch (entry.elementtype) {
        case "elements/elements":
          break;
        case "elements/headlines":
          parsedElement = this.parseHeadline(entry);
          break;
        case "elements/texts":
          parsedElement = this.parseText(entry);
          break;
        case "elements/media":
          parsedElement = this.parseMedia(entry);
          break;
        case "elements/questions":
          parsedElement = this.parseQuestion(entry);
          break;
        case "elements/pages":
          parsedElement = this.parsePage(entry);
          elements.push(parsedElement);
          break;
        case "elements/lines":
          parsedElement = new LineModel(entry.position);
          break;
        case "elements/spaces":
          parsedElement = this.parseSpace(entry);
          break;
        case "elements/blockopens":
          parsedElement = this.parseBlock(entry);
          elements.push(parsedElement);
          break;
        case "elements/blockcloses":
          // find corresponding block opens element
          let filtered = elements.filter((p) => p instanceof BlockModel);
          let block = filtered[filtered.length - 1];
          let spliced = elements.splice(
            elements.lastIndexOf(block),
            elements.length - 1
          );
          parsedElement = spliced[0];
          // in case of conditional pages append them to the corresponding block
          // if (spliced.length > 1) parsedElement.elements.concat(spliced.slice(1))
          break;
        default:
          throw "switch default!";
      }
      if (parsedElement && entry.elementtype != "elements/blockcloses") {
        parsedElement.position = entry.position;
      }
      if (
        parsedElement &&
        entry.elementtype != "elements/blockopens" &&
        entry.elementtype != "elements/pages"
      ) {
        elements[elements.length - 1].elements.push(parsedElement);
      }
    }

    return elements as Array<PageModel>;
  }

  /**
   * @method parseHeadline
   * @param content
   * @return QuestionModel for a simple headline with no actual question and its tiype
   *
   * parse response part into Headline QuestionModel
   */
  public parseHeadline(content: string): HeadlineModel {
    let headline = new HeadlineModel(
      null,
      content["headline"],
      content["color"]
    );
    if (headline.headline == null) {
      headline.headline = "No headline";
    }
    return headline;
  }

  /**
   * @method parseText
   * @param content
   * @return QuestionModels for a simple text with no actual question and its type.
   */
  public parseText(content: string): TextModel {
    let parsedType: TextType;
    switch (content["type"]) {
      case "none":
        parsedType = TextType.NONE;
        break;
      case "important":
        parsedType = TextType.IMPORTANT;
        break;
      case "tip":
        parsedType = TextType.TIP;
        break;
      case "info":
        parsedType = TextType.INFO;
        break;
      case "success":
        parsedType = TextType.SUCCESS;
        break;
      case "highlight":
        parsedType = TextType.HIGHLIGHT;
        break;
      case "table":
        parsedType = TextType.TABLE;
        break;
      default:
        parsedType = TextType.NONE;
    }
    let text = content["text"];
    let parser = new DOMParser();
    let htmlDoc = parser.parseFromString(text, "text/html");
    let imgs = htmlDoc.getElementsByTagName("img");
    for (let i = 0; i < imgs.length; i++) {
      let img = imgs[i];
      if (img.width > 0) {
        img.style.setProperty(
          "width",
          img.width.toString() + "px",
          "important"
        );
      }
    }
    // let textSanitized = this.sanitizer.bypassSecurityTrustHtml(text);
    return new TextModel(null, text, parsedType);
  }

  /**
   * @method parseMedia
   * @param content
   * @return Media
   */
  parseMedia(content: string): MediaModel {
    // set type for media model
    let mimeType = content["mimetype"];
    let isAudio = false;
    let type: MediaType;
    switch (mimeType) {
      case "image/png":
        type = MediaType.IMAGE_PNG;
        break;
      case "image/jpeg":
        type = MediaType.IMAGE_JPEG;
        break;
      case "image/gif":
        type = MediaType.IMAGE_GIF;
        break;
      case "image/svg+xml":
        type = MediaType.IMAGE_SVG;
        break;
      case "audio/mpeg":
        type = MediaType.AUDIO_MPEG;
        break;
      case "audio/wav":
      case "audio/x-wav":
        type = MediaType.AUDIO_WAV;
        break;
      case "audio/x-m4a":
        type = MediaType.AUDIO_M4A;
        break;
      case "video/mp4":
        // try catch block for backward compatibility
        // old interventions miss the is_audio key in video/mp4 elements
        try {
          isAudio = content["is_audio"] == 1;
        } catch (e) {}
        type = MediaType.VIDEO_MP4;
        break;
      case "application/msword":
        type = MediaType.APPLICATION_DOC;
        break;
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        type = MediaType.APPLICATION_DOCX;
        break;
      case "application/vnd.ms-powerpoint":
        type = MediaType.APPLICATION_PPT;
        break;
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        type = MediaType.APPLICATION_PPTX;
        break;
      case "application/vnd.oasis.opendocument.text":
        type = MediaType.APPLICATION_ODT;
        break;
      case "application/pdf":
        type = MediaType.APPLICATION_PDF;
        break;
      default:
        type = MediaType.NOT_SUPPORTED;
    }

    let uri = content["uri"];
    if (uri == null) {
      uri = "Empty";
    }

    // get name of media model as accessed at the back-end
    let currentIndexOfChar = 0;
    let indexOfLastChar = 0;
    while (currentIndexOfChar != -1) {
      indexOfLastChar = currentIndexOfChar;
      currentIndexOfChar = uri.indexOf("/", currentIndexOfChar + 1);
    }
    let file = uri.substr(indexOfLastChar + 1);

    // set data for the model
    let platformIsAndroid = false;
    if (this.platform.is("android")) {
      platformIsAndroid = true;
    }

    let width = content["width"];
    let height = content["height"];
    let banner = content["banner"];
    if (banner == 1) {
      banner = true;
    } else {
      banner = false;
    }

    return new MediaModel(
      null,
      type,
      isAudio,
      uri,
      uri,
      content["description"],
      content["subtitle"],
      content["filename"],
      file,
      width,
      height,
      banner,
      platformIsAndroid
    );
  }

  /**
   * @method parseQuestion
   * @param content
   * @return QuestionModel and its Type
   *
   * Parse part of response into corresponding Questiontype
   */
  public parseQuestion(content: string): ElementModel {
    let questiontype: string = content["questiontype"];
    let questionModel = null;
    switch (questiontype) {
      case "YesNoSwitch":
        questionModel = this.parseYesNoQuestion(content);
        break;
      case "SingleChoice":
        questionModel = this.parseSingleChoice(content);
        break;
      case "MultipleChoice":
        questionModel = this.parseMultipleChoice(content);
        break;
      case "Slider":
        questionModel = this.parseSlider(content);
        break;
      case "QuestionTable":
        questionModel = this.parseQuestionTable(content);
        break;
      case "TextDate":
        questionModel = this.parseTextDate(content);
        break;
      case "TextString":
        questionModel = this.parseTextString(content);
        break;
      case "TextArea":
        questionModel = this.parseTextArea(content);
        break;
      default:
        return null;
    }

    if (questionModel == null) {
      return null;
    }

    return questionModel;
  }

  /**
   * @method parseYesNoQuestion
   * @param content
   * @return QuestionYesNo
   *
   * parse response part into QuestionYesNoModel
   */
  private parseYesNoQuestion(content: string): QuestionYesNoModel {
    //initialise new model with content provided
    let required: boolean = false;
    if (content["required"] == 1) {
      required = true;
    }
    return new QuestionYesNoModel(
      null,
      content["question"],
      required,
      content["label"]
    );
  }

  /**
   * @method parseSingleChoice
   * @param content
   * @return parseSingleChoiceModel
   *
   * parse response part into QuestionSingleChoiceModel
   */
  private parseSingleChoice(content: string): QuestionSingleChoiceModel {
    //initialise new model with content provided
    let required: boolean = false;
    if (content["required"] == 1) {
      required = true;
    }

    let options: Array<string> = [];
    if (content["answers"] != null) {
      for (let answer of content["answers"]) {
        options.push(answer);
      }
    }

    return new QuestionSingleChoiceModel(
      null,
      content["question"],
      required,
      content["label"],
      content["answers"],
      content["values"]
    );
  }

  /**
   * @method parseMultipleChoice
   * @param content
   * @return parseMultipleChoiceModel
   *
   * parse response part into QuestionMultipleChoiceModel
   */
  private parseMultipleChoice(content: string): QuestionMultipleChoiceModel {
    //initialise new model with content provided
    let required: boolean = false;
    if (content["required"] == 1) {
      required = true;
    }

    let options: Array<string> = [];
    if (content["answers"] != null) {
      for (let answer of content["answers"]) {
        options.push(answer);
      }
    }

    return new QuestionMultipleChoiceModel(
      null,
      content["question"],
      required,
      content["label"],
      content["answers"],
      content["values"]
    );
  }

  /**
   * @method parseSlider
   * @param content
   * @return parseSliderModel
   *
   * parse response part into QuestionSliderModel
   */
  private parseSlider(content: string): QuestionSliderModel {
    let required: boolean = false;
    if (content["required"] == 1) {
      required = true;
    }

    let leftLabel: string = "No left label";
    let rightLabel: string = "No right label";
    if (content["answers"] != null) {
      leftLabel = content["answers"][0]["label"];
      rightLabel = content["answers"][1]["label"];
    }

    return new QuestionSliderModel(
      null,
      content["question"],
      required,
      content["label"],
      leftLabel,
      rightLabel,
      content["values"]["min"],
      content["values"]["max"],
      content["values"]["step"],
      content["values"]["start"]
    );
  }

  /**
   * @method parseQuestionTable
   * @param content
   * @return parseQuestionTableModel
   *
   * parse response part into QuestionTableModel
   */
  private parseQuestionTable(content: string): QuestionTableModel {
    let required: boolean = false;
    if (content["required"] == 1) {
      required = true;
    }

    return new QuestionTableModel(
      null,
      content["question"],
      required,
      content["label"],
      content["answers"],
      content["values"]["min"],
      content["values"]["max"]
    );
  }

  /**
   * @method parseTextDate
   * @param content
   * @return parseTextDateModel
   *
   * parse response part into QuestionTextDateModel
   */
  private parseTextDate(content: string): QuestionDateModel {
    //initialise new model with content provided
    let required: boolean = false;
    if (content["required"] == 1) {
      required = true;
    }

    let type = "date";
    if (content["values"] != null) {
      type = content["values"][0];
    }

    return new QuestionDateModel(
      null,
      content["question"],
      required,
      content["label"],
      type
    );
  }

  /**
   * @method parseTextString
   * @param content
   * @return parseTextStringModel
   *
   * parse response part into QuestionTextStringModel
   */
  private parseTextString(content: string): QuestionTextStringModel {
    //initialise new model with content provided
    let required: boolean = false;
    if (content["required"] == 1) {
      required = true;
    }

    return new QuestionTextStringModel(
      null,
      content["question"],
      required,
      content["label"]
    );
  }

  /**
   * @method parseTextArea
   * @param content
   * @return parseTextAreaModel
   *
   * parse response part into QuestionTextAreaModel
   */
  private parseTextArea(content: string): QuestionTextAreaModel {
    //initialise new model with content provided
    let required: boolean = false;
    if (content["required"] == 1) {
      required = true;
    }

    return new QuestionTextAreaModel(
      null,
      content["question"],
      required,
      content["label"]
    );
  }

  private parsePage(content: any): PageModel {
    let color;
    try {
      color = content["color"];
    } catch {
      color = "";
    }

    let condition;
    try {
      condition = content["condition"] === null ? "" : content["condition"];
    } catch {
      condition = "";
    }

    let progressbar_text;
    try {
      progressbar_text =
        content["progressbar_text"] === "" ? null : content["progressbar_text"];
    } catch {
      progressbar_text = null;
    }

    return new PageModel(null, color, condition, progressbar_text);
  }

  private parseSpace(content: any): SpaceModel {
    let size;
    try {
      size = parseInt(content["attributes"]["elements"]["size"]);
    } catch {
      size = 1;
    }

    return new SpaceModel(null, size);
  }

  private parseBlock(content: string): BlockModel {
    let typeJson = content["type"];
    let type: BlockType;
    switch (typeJson) {
      case "none":
        type = BlockType.NONE;
        break;
      case "details":
        type = BlockType.DETAILS;
        break;
      case "conditional":
        type = BlockType.CONDITIONAL;
        break;
      case "both":
        type = BlockType.BOTH;
        break;
    }
    let text = content["text"];
    let condition = content["condition"]["ifBlock"];
    try {
      let repeat = content["condition"]["thenBlock"]["repeat"];
      return new BlockModel(null, text, type, condition, repeat);
    } catch (e) {
      this.alertService.showError(
        "ERROR.ERROR",
        "ERROR.ERROR_COULD_NOT_LOAD_LESSON_OLD_MODEL"
      );
    }
  }

  public parseThreads(threadJSON: object, type: string): Array<Thread> {
    let newMessagesNumber = 0;
    let newFeedbackNumber = 0;
    let threadsArr = threadJSON["body"].data;
    let result = [];
    console.log(threadsArr);
    for (let index in threadsArr) {
      let json = {
        id: threadsArr[index].id,
        attributes: threadsArr[index].attributes,
        includedUsers: threadsArr[index].relationships.participants.data,
        includedMessages: threadsArr[index].relationships?.messages?.data
          ? threadsArr[index].relationships?.messages?.data
          : [],
      };
      let thread = this.parseThread(json);
      result.push(thread);

      if (!thread.read) {
        if (type == "messages") {
          newMessagesNumber++;
        } else if (type == "feedback") {
          newFeedbackNumber++;
        }
      }
    }

    if (type == "messages") {
      this.newMessages = newMessagesNumber;
    } else {
      this.newFeedback = newFeedbackNumber;
    }

    let threadsSorted = this.helperService.sortThreadsByNewestDate(result);
    return threadsSorted;
  }

  public parseThread(threadJSON: object): Thread {
    let attributes = threadJSON["attributes"];
    let id = threadJSON["id"];
    let includedUsers = threadJSON["includedUsers"];
    let includedMessages = threadJSON["includedMessages"];

    if (threadJSON["body"]) {
      attributes = threadJSON["body"].data.attributes;
      id = threadJSON["body"].data.id;
      includedUsers = threadJSON["body"].data.relationships.participants.data;
      includedMessages = threadJSON["body"].data.relationships?.messages?.data
        ? threadJSON["body"].data.relationships.messages.data
        : [];
    }

    let messages = [];
    for (let messageIndex in includedMessages) {
      let messageId = includedMessages[messageIndex].id;
      let message = includedMessages[messageIndex].attributes;
      let messageAuthorName = this.getAuthorName(
        includedMessages[messageIndex].relationships.author.data
      );
      messages.push(
        new Message(
          messageId,
          includedMessages[messageIndex].relationships.author?.data?.id,
          messageAuthorName,
          message.body,
          message.created_at
        )
      );
    }
    let participants = [];
    let subjectText = "";
    for (let participantIndex in includedUsers) {
      let participantId = includedUsers[participantIndex].id;
      let name = this.getAuthorName(includedUsers[participantIndex]);
      participants.push({
        id: participantId,
        name: name,
      });
      //for chat thread: subjectText is name of participants
      if (subjectText != "") {
        subjectText += ", ";
      }
      subjectText += name;
    }

    // Set picture of thread to picture of last sender
    let authorId = 1;
    if (messages.length > 0) {
      authorId = messages[messages.length - 1].authorId;
    }
    let picture = this.helperService.getUserPicture(authorId);

    //when requesting a thread it is set to read & unread messages = 0; if feedback it is set to the correct value from progress below
    let read = true;
    let unreadMessages = 0;
    if (
      (!attributes.answersheet_id ||
        (attributes.answersheet_id &&
          !attributes.intervention_instance_progress
            .questionnaire_feedback_required)) &&
      attributes.unread
    ) {
      read = !attributes.unread.is_unread;
      unreadMessages = attributes.unread.messages;
    }

    //for feedback thread: subjectText is name of lesson; progress.feedback_required read attribute used for (un)read messages
    if (attributes.answersheet_id) {
      subjectText =
        attributes.intervention_instance_progress.questionnaire_name;
      if (
        attributes.intervention_instance_progress
          .questionnaire_feedback_required
      ) {
        read =
          attributes.intervention_instance_progress.questionnaire_feedback_read;
      }
      //if instance canceled
      if (
        attributes.intervention_instance_progress.questionnaire_feedback_read ==
        null
      ) {
        read = !attributes.unread.is_unread;
      }
    }

    let thread = new Thread(
      id,
      subjectText,
      attributes.answersheet_id,
      attributes.intervention_instances_id,
      attributes.intervention_instance_progress.questionnaire_id,
      attributes.intervention_instance_progress.questionnaire_feedback_required,
      read,
      attributes.created_at,
      unreadMessages,
      messages,
      participants,
      picture
    );

    return thread;
  }

  private getAuthorName(authorJSON: object): string {
    if (!!authorJSON) {
      let name = authorJSON["attributes"].name;
      if (authorJSON["id"] == this.localStorage.getUserId()) {
        name = this.translationService.instant("CHAT_FEEDBACK.ME");
      } else if (
        authorJSON["attributes"].firstname &&
        authorJSON["attributes"].lastname
      ) {
        name =
          authorJSON["attributes"].firstname +
          " " +
          authorJSON["attributes"].lastname;
      } else if (name === "") {
        name = this.translationService.instant("CHAT_FEEDBACK.UNKNOWN");
      }
      return name;
    } else {
      return this.translationService.instant("CHAT_FEEDBACK.UNKNOWN");
    }
  }

  public parseAnnouncements(annArrJSON: object): Array<Announcement> {
    let annArr = annArrJSON["body"].data;
    let result = [];
    for (let index in annArr) {
      let attributesJSON = annArr[index].attributes;
      result.push(
        new Announcement(
          annArr[index].id,
          attributesJSON.type,
          attributesJSON.title,
          attributesJSON.message
        )
      );
    }
    return result;
  }

  public parseSkills(skillArrJSON: object): Array<Skill> {
    let skillArr = skillArrJSON["body"].data;
    let result = [];
    for (let index in skillArr) {
      let attributesJSON = skillArr[index].attributes;
      result.push(this.parseSkill(skillArr[index]));
    }
    return result;
  }

  public parseSkill(skillJSON: object): Skill {
    let attributes = skillJSON["attributes"];
    let id = skillJSON["id"];
    if (skillJSON["body"]) {
      attributes = skillJSON["body"].data.attributes;
      id = skillJSON["body"].data.id;
    }
    let result = new Skill(
      id,
      attributes.intervention_id,
      attributes.title,
      attributes.color,
      attributes.icon,
      null,
      attributes.study_id
    );
    return result;
  }
}
