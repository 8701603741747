import {ElementModel} from "./ElementModel";

export class SpaceModel extends ElementModel{
  size: number

  constructor(position: number, size: number) {
    super(position);
    this.size = size;
  }
}
