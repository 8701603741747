import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ToastController } from "@ionic/angular";
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(
    private alertController: AlertController,
    private loadingController: LoadingController,
    private translateService: TranslateService,
    private toastController: ToastController
  ) {
  }

  /**
   * @method presentLoading
   *
   * Presents the standard loading modal.
   */
  public async presentLoading() {
    try {
      await this.loadingController.dismiss();
    } catch (e) { }
    let message = await this.translateService.instant("GENERAL.PLEASE_WAIT");
    const loading = await this.loadingController.create({
      message: message,
      backdropDismiss: false,
      translucent: true,
      cssClass: 'custom-class custom-loading'
    });
    return await loading.present();
  }

  /**
   * @method dismissLoading
   *
   * Dismisses the loading modal.
   */
  public dismissLoading() {
    this.loadingController.dismiss().catch();
  }

  /**
   * @method presentToast
   * @param message The message which will be presented on the toast. Has to be inside the translations file.
   *
   * Presents a toast with custom text.
   */
  public async presentToastWithoutClosing(message: string) {
    try {
      await this.toastController.dismiss();
    } catch (e) { }

    const toast = await this.toastController.create({
      message: this.translateService.instant(message),
      position: "top",
      buttons: [
        {
          text: 'x',
          role: 'cancel',
          handler: () => { }
        }
      ]
    });
    toast.present();
  }

  /**
   * @method presentToast
   * @param message The message which will be presented on the toast. Has to be inside the translations file.
   *
   * Presents a toast with custom text.
   */
  public async presentToast(message: string) {
    try {
      await this.toastController.dismiss();
    } catch (e) { }

    const toast = await this.toastController.create({
      message: this.translateService.instant(message),
      duration: 5000,
      position: "top",
      buttons: [
        {
          text: 'x',
          role: 'cancel',
          handler: () => { }
        }
      ]
    });
    toast.present();
  }

  public async dismissAllToasts() {
    try {
      await this.toastController.dismiss();
    } catch (e) { }
  }

  /**
   * error window
   */
  public async showError(title, errorText) {
    const alert = await this.alertController.create({
      header: this.translateService.instant(title),
      subHeader: this.translateService.instant(errorText),
      buttons: [this.translateService.instant('BUTTONS.OK')]
    });
    alert.present();
  }

  /**
   * error window with details
   */
  public async showErrorWithDetails(title, errorText, errorDetails) {
    const alert = await this.alertController.create({
      header: this.translateService.instant(title),
      subHeader: this.translateService.instant(errorText),
      message: errorDetails,
      buttons: [this.translateService.instant('BUTTONS.OK')]
    });
    alert.present();
  }

  /**
   * Info window
   */
  public async showChoice(title, infoText, button1, button2, callbackButton1, callbackButton2) {
    const alert = await this.alertController.create({
      header: this.translateService.instant(title),
      subHeader: this.translateService.instant(infoText),
      buttons: [{
        text: this.translateService.instant(button1),
        handler: () => {
          callbackButton1();
        },
        role: 'cancel'
      }, {
        text: this.translateService.instant(button2),
        handler: () => {
          callbackButton2();
        }
      }]
    });
    await alert.present();
  }
}
