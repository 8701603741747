export class Answersheet {
  id: number;
  lessonId: number;
  instanceID: number;
  diaryInstanceID: number;
  answers: Array<any>;
  locale: string;
  collected_at: number;
  version: string;

  constructor(id: number, lessonId: number, instanceID: number, diaryInstanceID: number, answers: Array<any>, locale: string, collected_at: number, version: string) {
    this.id = id;
    this.lessonId = lessonId;
    this.instanceID = instanceID;
    this.diaryInstanceID = diaryInstanceID;
    this.answers = answers;
    this.locale = locale;
    this.collected_at = collected_at;
    this.version = version;
  }
}
