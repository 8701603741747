import {Component, Input} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {HelperService} from "../../../providers/helper/helper.service";

@Component({
  selector: 'app-chat-information',
  templateUrl: './chat-information.component.html',
  styleUrls: ['./chat-information.component.scss'],
})
export class ChatInformationComponent {
  @Input() users: {id: number, name: string}[];

  constructor(
    private modalController: ModalController,
    private helperService: HelperService
  ) {
  }

  dismiss() {
    // using the injected ModalController this page can "dismiss" itself 
    this.modalController.dismiss({
      'dismissed': true
    });
  }

}
