import {QuestionModel} from "./QuestionModel";

export class QuestionSingleChoiceModel extends QuestionModel {
  resolvable = ['question', 'options']
  options: Array<string> = [];
  optionsResolved: Array<string>;
  values: Array<string> = [];

  constructor(position: number, question: string, required: boolean, label: string, options: string[], values: string[]) {
    super(position, question, required, label);
    this.options = options;
    this.values = values;
  }
}
