import { Component, Input, OnInit } from '@angular/core';
import { Question } from "../Question";
import { QuestionSliderModel } from "../../../models/elements/QuestionSliderModel";

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})

export class Slider extends Question implements OnInit {
  @Input() public loopIndex: number;
  public model: QuestionSliderModel;
  public manyPoints: boolean = false;
  value: number = null;
  // answered = null;

  constructor() {
    super();
  }

  ngAfterViewInit() {
    //emit null (so that there is an entry for each repetition) or saved answer
    this.emitAnswer(this.currentValue);
    // this.answered = (answer != null);
  }

  public ngOnInit(): void {
    this.manyPoints = this.model ? this.model.scaleRangeRight > 20 : false;
  }

  ngOnDestroy(){
    console.log("destroy pos " + this.model.position + " loop" +  this.loopIndex)
    this.model.lesson.removeAnswerRepetition(this.model.position, this.loopIndex);
  }

  public onAddToRange(): void {
    if(this.value == null){
      this.emitAnswer(this.model.start + 1);
    }else if(this.value < this.model.scaleRangeRight){
      this.value++;
      this.emitAnswer(this.value);
    }
  }

  public onSubtractFromRange(): void {
    if(this.value == null){
      this.emitAnswer(this.model.start - 1);
    }else if(this.value > this.model.scaleRangeLeft){
      this.value--;
      this.emitAnswer(this.value);
    }
  }

  public rangeFocused(value: CustomEvent) {
    //this is only used for when nothing selected and then start value should be selected
    if(this.currentValue === null){
      this.emitAnswer(this.model.start)
    }
  }

  public emitAnswer(value: any) {
    if (value === undefined || this.model.lesson.loading) {
      return
    }
    // insert technical answer in global array
    this.model.lesson.insertAnswer(this.model.position, this.loopIndex, value);
    this.value = this.currentValue;
  }

  get currentValue(): number {
    return this.model.lesson.getAnswer(this.model.position, this.loopIndex)
  }
}
