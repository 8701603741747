import { Component } from '@angular/core';
import { Element_ } from "../Element_";
import { SpaceModel } from "../../../models/elements/SpaceModel";

@Component({
  selector: 'app-space',
  templateUrl: './space.component.html',
  styleUrls: ['./space.component.scss'],
})
export class Space extends Element_ {
  model: SpaceModel;

  constructor() {
    super();
    this.model = this.model as SpaceModel;
  }
}
