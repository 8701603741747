import { AfterViewInit, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { Question } from '../Question';
import { QuestionSingleChoiceModel } from "../../../models/elements/QuestionSingleChoiceModel";

@Component({
  selector: 'app-single-choice',
  templateUrl: './single-choice.component.html',
  styleUrls: ['./single-choice.component.scss'],
})

export class SingleChoice extends Question implements AfterViewInit {
  @ViewChild('selectRef', { read: ElementRef }) selectRef: ElementRef;
  @Input() public loopIndex: number;
  public model: QuestionSingleChoiceModel;
  value: String = null;
  loading: boolean = false;
  oldOptions: Array<String> = null;

  constructor(
    private renderer: Renderer2
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.injectStyle();
    //emit null (so that there is an entry for each repetition) or saved answer
    this.emitAnswer(this.currentValue);
  }

  ngOnInit(){
    //reload ion-select on change of answers (references could have changed; if those are in selected answer, the text is not updated automatically)
    //only do this if there even is a reference in the selected answer and the resolved value changed
    this.model.lesson.answers.subscribe(x => {
      //get selected option unresolved & check if option has a reference & resolved option text changed
      let selectedOptionChanged = false;
      let indexOfOption = this.model.values.findIndex(value => value === this.currentValue);
      if(indexOfOption != -1){
        let selectedAnswerUnresolved = this.model.options[indexOfOption];
        if(selectedAnswerUnresolved.includes("{{") && this.oldOptions[indexOfOption] != this.model.optionsResolved[indexOfOption]){
          selectedOptionChanged = true;
        }
      }

      //check if selected
      if(selectedOptionChanged){
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
        }, 10);
      }
      this.oldOptions = JSON.parse(JSON.stringify(this.model.optionsResolved))
    })
  }

  ngOnDestroy(){
    console.log("destroy pos " + this.model.position + " loop" +  this.loopIndex)
    this.model.lesson.removeAnswerRepetition(this.model.position, this.loopIndex);
  }

  /**
   * Styling shadow DOM. As there is no hook for when shadow DOM has finished loading, we catch nullpointer from ion-select
   * styling and try again.
   */
  private injectStyle() {
    try {
      this.renderer.setStyle(this.selectRef.nativeElement.shadowRoot.childNodes[0], 'white-space', "normal");
      this.renderer.setStyle(this.selectRef.nativeElement.shadowRoot.childNodes[0], 'text-overflow', "unset");
      this.renderer.setStyle(this.selectRef.nativeElement.shadowRoot.childNodes[0], 'word-break', "break-word");
      this.renderer.setStyle(this.selectRef.nativeElement.shadowRoot.childNodes[1], 'align-self', "center");
    } catch (TypeError) {
      setTimeout(() => {
        this.injectStyle()
      }, 100)
    }
  }

  public emitAnswer(value: any) {
    if (value === undefined || this.model.lesson.loading) {
      return
    }
    // insert technical answer in global array
    this.model.lesson.insertAnswer(this.model.position, this.loopIndex, value);
    this.value = this.currentValue;
  }

  get currentValue(): String {
    return this.model.lesson.getAnswer(this.model.position, this.loopIndex)
  }
}
