import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {ModalController} from '@ionic/angular';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import * as confetti from 'canvas-confetti';
import {RequestProviderService} from '../../../providers/request-provider/request-provider.service';

@Component({
  selector: 'app-buddy-popup',
  templateUrl: './buddy-popup.component.html',
  styleUrls: ['./buddy-popup.component.scss'],
})
export class BuddyPopupComponent implements AfterViewInit {
  @ViewChild('confettiCanvasRef', { static: true }) confettiCanvasRef: ElementRef;
  @Input() dialogData: { dailyTaskDone: boolean, dailyTaskDoneBuddy: boolean, reminded: boolean, remindedBuddy: boolean };
  @Input() buddyInstanceId: number;
  justReminded = false; //reminded just says that the buddy was reminded, not if it was now or on last open of window; if this is true it was right now, if not it was on earlier open
  disableButton = false;

  constructor(
    private modalController: ModalController,
    private requestProvider: RequestProviderService
  ) { }

  ngAfterViewInit() {
    if (this.dialogData.dailyTaskDone && this.dialogData.dailyTaskDoneBuddy) {
      this.fireConfetti();
    }
  }

  onCloseDialog() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss();
  }

  sendReminderMail() {
    this.disableButton = true;
    this.requestProvider.sendBuddyReminder(this.buddyInstanceId).pipe(map((result) => result), catchError(error => {return throwError(error)}))
      .subscribe((response) => {
        this.disableButton = false;
        this.dialogData.reminded = true;
        this.justReminded = true;
        this.fireConfetti();
      }, (error) => {
        this.disableButton = false
      });
  }

  private fireConfetti(): void {
    const duration = 3_000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 10, spread: 360, ticks: 60, zIndex: 0 };

    const canvas: any = {};
    canvas.confetti = canvas.confetti || confetti.create(this.confettiCanvasRef.nativeElement, { resize: true });

    // Fire two confetti instances every 250 ms
    const interval = setInterval(() => {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      const randomInRange = (min: number, max: number) => Math.random() * (max - min) + min;

      const particleCount = 75 * (timeLeft / duration);
      // since particles fall down, start a bit higher than random
      canvas.confetti(Object.assign({}, defaults, {
        particleCount,
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 }
      }));
      canvas.confetti(Object.assign({}, defaults, {
        particleCount,
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 }
      }));
    }, 350);
  }

}
