import {Component, ViewEncapsulation} from '@angular/core';
import {Element_} from "../Element_";
import {TextModel} from "../../../models/elements/TextModel";
import {TextType} from "../../../../app/models/elements/Enums";
import {Platform} from '@ionic/angular';


@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
  encapsulation: ViewEncapsulation.None, // THIS IS IMPORTANT, without you can't style data passed via [innerHTML]
})
export class Text_ extends Element_ {

  model: TextModel;
  tableWidth: number;

  constructor(
    private platform: Platform
  ) {
    super();
    this.model = this.model as TextModel;
  }

  get TextType() {return TextType;}

  getIcon() {
    if (this.model.type == this.TextType.IMPORTANT) {
      return "alert";
    } else if (this.model.type == this.TextType.TIP) {
      return "bulb";
    } else if (this.model.type == this.TextType.INFO) {
      return "information-circle-outline";
    } else if (this.model.type == this.TextType.SUCCESS) {
      return "checkmark";
    }
  }

  get isMobile(): boolean {return this.platform.is('mobileweb');}

  get numberOfColumns(): number {
    return JSON.stringify(this.model.text).split("<tbody>")[1].split("<tr>")[1].split("<td>").length - 1
  }
}
